import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { showToastError, showToastSuccess } from 'services/toaster';

import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';

import { deleteServices } from 'api/methods/booking/services';

const BASE_UI_PATH = ['booking', 'deleteServices'];

export function* deleteServicesWorker({ payload }) {
  const { bookingId, servicesId } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, servicesId],
  }));

  try {
    yield call(deleteServices, token, { pathParams: { id: bookingId, servicesId } });

    yield put(actions.deleteBookingServicesSuccess(servicesId));

    showToastSuccess();
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    showToastError();

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, servicesId, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, servicesId, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, servicesId, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, servicesId, 'completed'] }));
  }
}
