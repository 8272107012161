import { put, select, call } from 'redux-saga/effects';

import { showToastError } from 'services/toaster';

import { renameContractFile } from 'api/methods/claim/digitalContract';

import { getToken } from 'bus/auth/selectors';
import { createUi } from 'bus/ui/helpers';
import { registerUI, updateUI } from 'bus/ui/actions';
import { getClaimId } from 'bus/clientClaim/selectors';

import { digitalContractActions } from '../../../actions';

import { createRenameFileUIKey } from './helpers';

export function* renameFileSaga({ payload: { contractId, fileId, publicName } }) {
  const uiKey = createRenameFileUIKey(fileId);
  const claimId = yield select(getClaimId);

  yield put(registerUI(createUi({ loading: true }), uiKey));

  try {
    const token = yield select(getToken);

    yield call(
      renameContractFile,
      token,
      {
        pathParams: { claimId, fileId, contractId },
        bodyParams: { public_name: publicName }
      }
    );

    yield put(digitalContractActions.renameFileSuccess(contractId, fileId, publicName));
  } catch (error) {
    debugger;
    yield put(digitalContractActions.renameFileFail());

    showToastError(error.msg?.body?.message);
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}
