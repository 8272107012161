// instruments
import BaseApiClass from 'api/BaseApiClass';

export const createServices = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/services',
  method: 'POST',
  ...params,
})._load({ token });

export const updateServices = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/services/{:servicesId}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteServices = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/services/{:servicesId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const createBookingService = (token, params = {}) => new BaseApiClass({
  url: 'booking-service',
  method: 'POST',
  ...params,
})._load({ token });
