import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useScript } from 'services/helmet/tags/Script';

import { getProfile } from 'bus/auth/selectors';

import { setInitialized } from './store/actions';
import { WIDGET_URL, WIDGET_ID } from './constants';
import { bootstrap } from './helpers';
import { getShow } from './store/selectors';

const withShow = Component => () => {
  const isShow = useSelector(getShow);

  return isShow && <Component />;
};

const Freshdesk = () => {
  const { username: name, email } = useSelector(getProfile);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.fwSettings = {
      widget_id: WIDGET_ID,
    };

    if (typeof window.FreshworksWidget !== 'function') {
      const fwScript = document.createElement('script');
      fwScript.innerHTML = `
        !function() {
          if ("function" != typeof window.FreshworksWidget) {
            var n = function() { n.q.push(arguments) };
            n.q = [], window.FreshworksWidget = n;
          }
        }();
      `;
      document.head.appendChild(fwScript);

      return () => {
        document.head.removeChild(fwScript);
      };
    }
  }, []);

  useScript(WIDGET_URL, true, () => {
    dispatch(setInitialized(true));

    bootstrap({
      name,
      email,
    });
  });

  return null;
};

export default withShow(Freshdesk);
