import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUserTfCustomization } from 'bus/auth/selectors';

import { CRM_TYPES } from 'crms/constants';

import starsLogo from 'assets/5stars-yellow.svg';
import MtLogo from 'assets/logo_mt_uk.svg';
import tatLogo from 'assets/logo_tat.svg';
import ebSvg from 'assets/EBLogo.svg';
import nkLogo from 'assets/logo_nk.svg';
import multitourLogo from 'assets/logo_multitour.svg';

import mtMobileLogo from 'assets/icons/mobile-logo.svg';
import mobileLogoTat from 'assets/icons/mobile-logo-tat.svg';
import mobileLogoNk from 'assets/icons/nk_logo_mobile.svg';

import Styles from './styles.scss';

const DefaultLogo = ({ isMobile, onClick }) => {
  const logo = useMemo(() => {
    switch (__CRM__) {
      case CRM_TYPES.mt:
        return isMobile ? mtMobileLogo : MtLogo;
      case CRM_TYPES.tat:
        return isMobile ? mobileLogoTat : tatLogo;
      case CRM_TYPES['5stars']:
        return starsLogo;
      case CRM_TYPES.easybooking:
        return ebSvg;
      case CRM_TYPES.nakanikuly:
        return isMobile ? mobileLogoNk : nkLogo;
      case CRM_TYPES.multitour:
        return multitourLogo;
      default:
        return isMobile ? mtMobileLogo : MtLogo;
    }
  }, [isMobile]);

  return (
    <div
      className={isMobile ? Styles.mobileMenuLogo : Styles.logo}
      dangerouslySetInnerHTML={{ __html: logo }}
      onClick={onClick}
    />
  );
};

const MainLogo = ({ isMobile, onClick }) => {
  const { mobileLogoPath, logoPath } = useSelector(getUserTfCustomization);

  if (mobileLogoPath || logoPath) {
    const src = isMobile ? (mobileLogoPath || logoPath) : logoPath;

    return (
      <img
        onClick={onClick}
        alt="logo"
        className={isMobile ? Styles.customLogo__mobile : Styles.customLogo}
        src={src}
      />
    );
  }

  return (
    <DefaultLogo
      isMobile={isMobile}
      onClick={onClick}
    />
  );
};

export default memo(MainLogo);
