import { put, race, select, takeEvery, take } from 'redux-saga/effects';
import * as R from 'ramda';

import { getOperators } from 'bus/common/selectors';
import { commonActions } from 'bus/common/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { showToastError } from 'services/toaster';
import { newInstance } from 'localization';

import { bootstrap, setIdentifier } from './actions';
import { BUS_TICKETS_BOOTSTRAP_UI } from './constants';

import { BOOKING_OPERATOR_ID } from '../constants';

function* bootstrapSaga() {
  yield put(registerUI(createUi({ loading: true }), BUS_TICKETS_BOOTSTRAP_UI));

  yield put(commonActions.getOperatorsMt({ with_credentials: true }));

  const [getOperatorsSuccess] = yield race([
    take(commonActions.getOperatorsMtSuccess),
    take(commonActions.getOperatorsMtFail)
  ]);

  if (getOperatorsSuccess) {
    const operatorIdentifier = yield select(state => {
      const operators = getOperators(state);

      const operator = R.find(R.propEq(BOOKING_OPERATOR_ID, 'id'), operators);

      return R.ifElse(
        Boolean,
        R.pipe(
          R.prop('tfCredentials'),
          R.find(({ sub_agent_identifier: identifier }) => identifier),
          R.prop('sub_agent_identifier')
        ),
        R.always(null)
      )(operator);
    });

    if (operatorIdentifier) {
      yield put(setIdentifier(operatorIdentifier));
    }
  } else showToastError(newInstance.t('ERRORS:ERROR_LOAD_DATA'));

  yield put(updateUI({ loading: false, completed: true }, BUS_TICKETS_BOOTSTRAP_UI));
}

export default function* () {
  yield takeEvery(bootstrap, bootstrapSaga);
}
