import * as R from 'ramda';

import RejectedIcon from 'assets/icons/close.svg';
import ConfirmIcon from 'assets/icons/check-icon.svg';

import { newInstance } from '../../localization';

export const BOOKING_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const NEW_STATUS = 'new';
const PREBOOKING_STATUS = 'prebooking';
const AVAILABLE_FOR_PAYMENT_STATUS = 'availableForPayment';
const CONFIRMED_STATUS = 'confirmed';
const EXPIRED = 'expired';
const CANCELED_STATUS = 'canceled';
const ON_BOOKING_STATUS = 'onBooking';
const NOT_CONFIRMED = 'notConfirmed';
const WITHOUT_STATUS = 'withoutStatus';

export const STATUSES_LIST_BY_CLAIM_STATUS = [
  NEW_STATUS,
  PREBOOKING_STATUS,
  AVAILABLE_FOR_PAYMENT_STATUS,
  CONFIRMED_STATUS,
  EXPIRED,
  CANCELED_STATUS,
  NOT_CONFIRMED,
  ON_BOOKING_STATUS,
  WITHOUT_STATUS
];

export const BOOKING_STATUS_MAP = {
  [NEW_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:RESERVATION');
    }
  },
  [PREBOOKING_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:PRELIMINARY');
    }
  },
  [AVAILABLE_FOR_PAYMENT_STATUS]: {
    icon: ConfirmIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:AVAILABLE_FOR_PAYMENT');
    }
  },
  [CONFIRMED_STATUS]: {
    icon: ConfirmIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:CONFIRMED');
    }
  },
  [EXPIRED]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:EXPIRED');
    }
  },
  [CANCELED_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:CANCELLED');
    }
  },
  [NOT_CONFIRMED]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:NOT_CONFIRMED');
    }
  },
  [ON_BOOKING_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:ON_BOOKING');
    }
  },
  [WITHOUT_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:NO_STATUS');
    }
  },
};

export const CALCULATION_TYPES = {
  THROUGH_CO: 0,
  THROUGH_CO_GROSS: 4,
  GROSS_FROM_AGENT: 5,
  PAYBACK_FROM_CO: 3,
  TICKETS_FROM_TS: 6,
  TICKETS_FROM_TS_GROSS: 7,
  PERCENT_CO: 1,
  OWN_BOOKING: 2,
};

export const BOOKING_CALCULATION_TYPES_OPTIONS = R.pipe(
  R.toPairs,
  R.map(([key, value]) => ({
    value,
    get text() {
      return newInstance.t(`BOOKING_MONEY:${key}`);
    }
  })),
)(CALCULATION_TYPES);

export const BOOKING_PAYMENT_SCHEDULE_OPTIONS = [
  {
    get text() { return newInstance.t('GLOBAL:ALL'); },
    value: null
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:NO_PAYMENT_SCHEDULE'); },
    value: CALCULATION_TYPES.PERCENT_CO
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:EXPIRED'); },
    value: CALCULATION_TYPES.OWN_BOOKING
  },
  {
    get text() { return newInstance.t('STAT:TODAY'); },
    value: CALCULATION_TYPES.PAYBACK_FROM_CO
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:TOMORROW'); },
    value: CALCULATION_TYPES.THROUGH_CO_GROSS
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:DAY_AFTER_TOMORROW'); },
    value: CALCULATION_TYPES.GROSS_FROM_AGENT
  },
];

export const BOOKING_SERVICES_TYPES_OPTIONS = [
  {
    value: 0,
    get text() {
      return newInstance.t('SERVICES_TYPES:HOTEL');
    }
  },
  {
    value: 1,
    get text() {
      return newInstance.t('SERVICES_TYPES:FLIGHT');
    }
  },
  {
    value: 2,
    get text() {
      return newInstance.t('SERVICES_TYPES:TRANSFER');
    }
  },
  {
    value: 3,
    get text() {
      return newInstance.t('SERVICES_TYPES:INSURANCE');
    }
  },
  {
    value: 4,
    get text() {
      return newInstance.t('SERVICES_TYPES:SURCHARGE');
    }
  },
  {
    value: 5,
    get text() {
      return newInstance.t('SERVICES_TYPES:EXCURSION');
    }
  },
  {
    value: 6,
    get text() {
      return newInstance.t('SERVICES_TYPES:LUGGAGE');
    }
  },
];
export const BOOKING_TRANSPORT_DIRECTION_MAP = {
  OUTBOUND: {
    get text() {
      return newInstance.t('CLAIM:OUTBOUND');
    },
    value: 0
  },
  INBOUND: {
    get text() {
      return newInstance.t('CLAIM:INBOUND');
    },
    value: 1
  },
  TRANSITIONAL: {
    get text() {
      return newInstance.t('CLAIM:TRANSITIONAL');
    },
    value: 2
  },
};

export const TRANSPORT_TYPES = {
  FLIGHT: 1,
  BUS: 2,
  SIGHTSEEING: 3,
  HOTEL_ONLY: 4,
  FLIGHT_TICKETS: 5,
  BUS_TICKETS: 6,
  TRANSFER: 7,
  INSURANCE: 8,
  PROMOTIONAL: 9,
  CRUISE: 10,
  OTHER: 11,
};
export const TRANSPORT_TYPES_PROPS = Object.fromEntries(
  Object.entries(TRANSPORT_TYPES).map(([key, value]) => ([
    value,
    {
      value,
      get text() {
        return newInstance.t(`BOOKING_TYPES:${key}`);
      }
    }
  ]))
);

export const TRANSPORT_TYPES_OPTIONS = Object.values(TRANSPORT_TYPES_PROPS);

export const BOOKING_STATUSES = {
  RESERVATION: 0,
  ON_BOOKING: 7,
  PRELIMINARY: 1,
  AVAILABLE_FOR_PAYMENT: 2,
  CONFIRMED: 3,
  EXPIRED: 4,
  CANCELLED: 5,
  NOT_CONFIRMED: 6,
  NO_STATUS: 8
};

export const BOOKING_STATUSES_PROPS = Object.fromEntries(
  Object.entries(BOOKING_STATUSES).map(([key, value]) => [
    value,
    {
      id: value,
      value,
      get text() {
        return newInstance.t(`BOOKING_STATUSES:${key}`);
      }
    }
  ])
);

export const BOOKING_STATUSES_OPTIONS = Object.values(BOOKING_STATUSES_PROPS);

export const BOOKING_SOURCES = {
  TOURSCANER: 2,
  PARSET: 1,
  FROM_CO: 0,
};
export const BOOKING_PROCESSING_STATUSES = {
  NEW: 0,
  PROCESSING: 1,
  PROCESSED: 2,
  DELAYED: 3,
};

export const CO_SERVICES_TYPES = {
  NON_ARRIVAL_INSURANCE: 1,
};

export const CO_SERVICES_TYPES_PROPS = Object.fromEntries(
  Object.entries(CO_SERVICES_TYPES).map(([key, value]) => [
    value,
    {
      value,
      get text() {
        return newInstance.t(`BOOKING_CO_SERVICES:${key}`);
      }
    }
  ])
);

export const CO_SERVICES_TYPES_OPTIONS = Object.values(CO_SERVICES_TYPES_PROPS);
