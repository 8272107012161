import { createActions } from 'redux-actions';

export const {
  showSignContractModal,
  signContract,
  setOpenedContractId,
  setActiveStep,
  resetStore,
} = createActions({
  showSignContractModal: (contractId, callbacks) => ({ contractId, callbacks }),
  signContract: (signData, onSuccess) => ({ signData, onSuccess }),
  setOpenedContractId: id => id,
  setActiveStep: step => step,
  resetStore: () => undefined,
}, { prefix: 'CONTRACT_SIGN_MODAL' });
