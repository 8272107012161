import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getUserTfCustomization } from 'bus/auth/selectors';

import { getFavIcon } from 'crms/helpers';

const faviconName = getFavIcon();

export const FaviconComponent = memo(() => {
  const {
    faviconPath
  } = useSelector(getUserTfCustomization);

  if (faviconPath) {
    return <link href={faviconPath} rel="shortcut icon" type="image/png" />;
  }

  return (
    // eslint-disable-next-line no-undef,camelcase
    <link href={`${__webpack_public_path__}static/favicon/${faviconName}`} rel="shortcut icon" type="image/png" />
  );
});
