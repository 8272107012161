import { createSelector } from 'reselect';

import * as R from 'ramda';
import { createUiSelector } from '../ui/helpers';

import { COUNTERPARTIES_UI, SEND_AKTS_UI, GET_COUNTERPARTY_INFO_UI } from './constants';

const domain = ({ accounting }) => accounting;

const DEFAULT_VALUE = [];
const EMPTY_OBJ = {};

export const getCounterparties = createSelector(domain, accounting => accounting.get('counterparties'));
export const getAktTemplate = createSelector(domain, accounting => accounting.get('aktTemplate'));
export const getOutboxInvoiceBase = createSelector(domain, accounting => accounting.get('outboxInvoiceBase') || DEFAULT_VALUE);
export const getCounterpartyInfo = createSelector(domain, accounting => accounting.get('counterpartyInfo') || EMPTY_OBJ);
export const getOrganizations = createSelector(domain, accounting => accounting.get('organizations'));
export const getContracts = createSelector(domain, accounting => accounting.get('contracts'));

export const getAccounts = R.pipe(
  domain,
  accounting => accounting.get('accounts')
);

export const getPayment = createSelector(domain, accounting => accounting.getIn(['payment', 'data']));
export const getInvoiceSendModalValues = createSelector(domain, accounting => accounting.getIn(['invoiceSendingModal', 'data']));
export const getSearchData = createSelector(domain, accounting => accounting.getIn(['search', 'data']));
export const getSearchOrder = createSelector(domain, accounting => accounting.getIn(['search', 'order']));
export const getSearchSort = createSelector(domain, accounting => accounting.getIn(['search', 'sort']));

export const getSendAktsUi = createUiSelector(SEND_AKTS_UI);
export const getCounterpartyInfoUI = createUiSelector(GET_COUNTERPARTY_INFO_UI);
export const getCounterpartiesUi = createUiSelector(COUNTERPARTIES_UI);
