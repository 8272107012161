import { call, select } from 'redux-saga/effects';

import { getSimilarHotels } from 'api/methods/tourscaner';

import { getToken } from 'bus/auth/selectors';

export function* getSimilarHotelsSaga({ payload: { callbacks, params } }) {
  const token = yield select(getToken);

  try {
    const { results } = yield call(
      getSimilarHotels,
      token,
      {
        queryParams: { hotel_id: params.hotelID, },
      }
    );

    callbacks?.onSuccess(results);
  } catch (error) {
    callbacks?.onFail(error);
  }
}
