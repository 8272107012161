import { createSelector } from 'reselect';
import * as R from 'ramda';

import { createUiSelector } from 'bus/ui/helpers';

import { getLocale, getUserId, getUserOrTfLocale } from '../auth/selectors';
import { getCredentialsResult } from '../tf/selectors';
import { GET_OPERATOR_REQUEST_LOGIN_UI } from './constants';

export const domain = ({ common }) => common;
export const getPatterns = createSelector(domain, common => common.get('patterns'));
export const getManagers = createSelector(domain, common => common.get('managers'));
export const getSortedManagers = createSelector(
  getManagers,
  getUserId,
  (managers, userId) => R.sortBy(
    R.pipe(
      R.prop('id'),
      R.equals(userId),
      R.not
    ),
    managers
  )
);

export const getMyManager = createSelector(
  getManagers,
  getUserId,
  (managers, userId) => R.find(R.propEq(userId, 'id'), managers)
);

export const getDocumentTypes = createSelector(domain, common => common.get('documentTypes'));

const getCountriesLocal = createSelector(domain, common => common.get('countries'));
export const getCountries = createSelector(
  [getCountriesLocal, getUserOrTfLocale],
  (countries, userLocale) => {
    return R.map(({ translations, name, ...rest }) => ({
      ...rest,
      translations,
      name: translations[userLocale] || name,
    }),
    countries
    );
  }
);

export const getOperators = createSelector(domain, common => common.get('operators'));

const getRolesBase = createSelector(domain, common => common.get('roles'));
export const getRoles = createSelector(
  [getRolesBase, getUserOrTfLocale], (roles, userLocale) => R.map(
    ({ description, translations, ...rest }) => ({
      ...rest,
      description: R.pathOr(description, [userLocale, 'description'], translations),
    }),
    roles
  )
);

const getActiveOperators = createSelector(getOperators, R.filter(({ isDeleted }) => !isDeleted));

export const getOperatorsWithCredentials = createSelector([getActiveOperators, getCredentialsResult],
  (operators, credentials) => R.map(
    operator => {
      const credentialsForOperator = R.filter(
        ({ operator: operatorCredential }) => operator.id === operatorCredential.id, credentials
      );

      return R.assoc('credentials', credentialsForOperator, operator);
    },
    operators
  )
);

export const getOperatorById = createSelector(
  getOperators,
  (_, { id }) => id,
  (operators, operatorId) => R.find(({ id }) => operatorId === id, operators)
);
export const getOperatorByOtpuskId = createSelector(
  getOperators,
  (_, { id }) => id,
  (operators, operatorId) => R.find(R.propEq(operatorId, 'otpusk_id'), operators)
);

export const getOperatorOptions = createSelector(
  getOperatorById,
  R.pipe(
    R.prop('options'),
    R.indexBy(R.prop('id'))
  )
);

export const getClosingReasons = createSelector(domain, common => common.get('closingReasons'));
export const getClosingReasonsWithTranslate = createSelector([getClosingReasons, getLocale],
  (reasons, userLocale) => R.map(({ translations, ...rest }) => ({
    ...rest,
    description: R.call(
      R.pipe(
        R.find(({ locale }) => locale === userLocale),
        translate => (translate ? translate.description : R.head(translations).description)
      ), translations),
  }), reasons)
);

export const getOffices = createSelector(domain, common => common.get('offices'));
export const getRegions = createSelector(domain, common => common.get('regions'));
export const getTags = createSelector(domain, common => common.get('tags'));
export const getDocs = createSelector(domain, common => common.get('docs'));
export const getDocsByOperators = createSelector(domain, common => common.get('docsByOperators'));
export const getAllNotificationTypes = createSelector(domain, common => common.get('notificationTypes'));

export const getDetails = createSelector(domain, common => common.get('details'));
export const getActiveDetails = createSelector(getDetails, details => details.filter(({ active }) => active));

export const getOperatorRequestLoginUI = createUiSelector(GET_OPERATOR_REQUEST_LOGIN_UI);
export const getManagersUI = createUiSelector(['common', 'fetchManagers'], true);
