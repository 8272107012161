import { toast, Toaster } from 'react-hot-toast';

import { newInstance } from 'localization';

export const showToastSuccess = (message = newInstance.t('GLOBAL:SAVE_SUCCESS'), options = {}) => {
  toast.success(message, options);
};

export const showToastError = (message = newInstance.t('ERRORS:ERROR'), options = {}) => {
  toast.error(message, options);
};

export {
  Toaster as NotificationsProvider
};
