import { handleActions, combineActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { detailDocsActions as actions } from './actions';

const initialState = Map({
  docs: {
    store: null,
    previewDoc: null,
    filterValue: null,
  },
  onceDoc: {
    store: {},
  },
  variables: [],
  extra: {},
  guarantees: [],
});

export default handleActions({
  [actions.fetchDocsSuccess]: (state, { payload: docs }) => state.setIn(['docs', 'store'], docs),
  [actions.clearDocs]: state => state.setIn(['docs', 'store'], null),
  [actions.deleteDocSuccess]: (state, { payload }) => {
    const { id } = payload;

    return state.updateIn(['docs', 'store'], docs => docs.filter(doc => doc.id !== id));
  },
  [actions.previewDoc]: (state, { payload: id }) => state.setIn(['docs', 'previewDoc'], id),
  [combineActions(
    actions.fetchDocSuccess,
    actions.createDocSuccess,
    actions.updateDocSuccess
  )]: (state, { payload }) => {
    const { id, doc } = payload;

    return state.setIn(['onceDoc', 'store', id], doc);
  },
  [actions.toggleDocSuccess]: (state, { payload: id }) => {
    const updateDocIndex = state.getIn(['docs', 'store'], []).findIndex(doc => doc.id === id);

    return state.updateIn(['docs', 'store', updateDocIndex, 'disabled'], flag => !flag);
  },
  [actions.clearDoc]: (state, { payload: id }) => state.setIn(['onceDoc', 'store', id], null),
  [actions.clearPreviewDoc]: state => state.setIn(['docs', 'previewDoc'], null),
  [actions.fetchDocVariablesSuccess]: (state, { payload: variables }) => state.set('variables', variables),
  [actions.clearDocVariables]: state => state.set('variables', []),
  [actions.fetchExtraDocSuccess]: (state, { payload }) => {
    const { id, extra } = payload;

    return state.setIn(['extra', id], extra);
  },
  [actions.clearExtraDoc]: (state, { payload: id }) => {
    return state.setIn(['extra', id], null);
  },
  [actions.fetchGuaranteesSuccess]: (state, { payload: data }) => state.set('guarantees', data),
  [actions.setDocsFilterValue]: (state, { payload: filterValue }) => {
    return state.setIn(['docs', 'filterValue'], filterValue);
  },
}, initialState);
