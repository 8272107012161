// instruments
import { bookingActions as actions } from 'bus/booking/actions';

export const bookingServicesReducer = {
  [actions.createBookingServicesSuccess]: (state, { payload: { service, id } }) => {
    if (!id) {
      return state.updateIn(['booking', 'data', 'services'], services => [...services, service]);
    }

    const serviceIdx = state
      .getIn(['booking', 'data', 'services'])
      .findIndex(item => item.id === id);

    return state.setIn(['booking', 'data', 'services', serviceIdx], service);
  },
  [actions.updateBookingServicesSuccess]: (state, { payload: services }) => {
    const serviceIdx = state
      .getIn(['booking', 'data', 'services'])
      .findIndex(({ id }) => id === services.id);

    return state.setIn(['booking', 'data', 'services', serviceIdx], services);
  },
  [actions.deleteBookingServicesSuccess]: (state, { payload: servicedId }) => {
    return state.updateIn(
      ['booking', 'data', 'services'],
      services => services.filter(({ id }) => id !== servicedId)
    );
  },
  [actions.createBookingServiceSuccess]: (state, { payload: service }) => {
    return state.updateIn(
      ['booking', 'data', 'childrenBookings'],
      services => [...services, service]
    );
  },
};
