import { select, call, put } from 'redux-saga/effects';

import { searchClientAndClaimActions } from 'bus/searchClientAndClaim/actions';
import { getLimitClientView, getToken, getUserId } from 'bus/auth/selectors';
import {
  SEARCH_CLIENT_COOKIE_KEY,
  SEARCH_CLIENT_UI_KEY,
  SEARCH_DIRECTOR_COOKIE_KEY
} from 'bus/searchClientAndClaim/constants';
import { withUIWorker } from 'bus/ui/helpers';

import { getClientAndClaim } from 'api/methods/search/getClientAndClaim';
import { getErrorMessage } from 'api/fn/parseErrors';

import { getDataFromSelectors, onSaveSearchToCache, withSetQuery2Url } from 'services/formCreator';

import { getOrder, getPage, getSearchClientAndClaimQuery, getTemplate } from '../../selectors';

import {
  prepareBodyParams,
  prepareDirectorParams,
  prepareDirectorSearchString,
  prepareClientsSearchString
} from '../../helpers';

function* searchStart({ type }) {
  const token = yield select(getToken);
  const query = yield select(getSearchClientAndClaimQuery);
  const page = yield select(getPage);
  const order = yield select(getOrder);
  const template = yield select(getTemplate);
  const limitClientView = yield select(getLimitClientView);
  const userId = yield select(getUserId);

  const isDirectorStatistic = type === searchClientAndClaimActions.searchDirectorStat.toString();
  try {
    const { result, total } = yield call(getClientAndClaim, token, {
      bodyParams: isDirectorStatistic
        ? prepareDirectorParams(query, page, limitClientView, userId)
        : prepareBodyParams(query, page, order, template, limitClientView, userId),
    });

    yield put(searchClientAndClaimActions.searchSuccess(result, total));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        400: 'Ошибка валидации',
        403: 'Необходимо иметь доступ к разделу "Клиенты". Запрещен или активна подписка только на Турсканер',
        404: 'Ошибка. Страницы не найдено',
        500: 'Ошибка сервера 500',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(searchClientAndClaimActions.searchFail(error));
  }
}

const actions = [searchClientAndClaimActions.searchSuccess, searchClientAndClaimActions.searchFail];
const dataSelectors = [getSearchClientAndClaimQuery, getPage, getLimitClientView, getUserId];

const uiSaga = withUIWorker(actions, SEARCH_CLIENT_UI_KEY)(searchStart);

export const searchClients = withSetQuery2Url(
  actions,
  {
    onGetData: getDataFromSelectors(dataSelectors, prepareClientsSearchString),
    onSuccess: onSaveSearchToCache(SEARCH_CLIENT_COOKIE_KEY),
  }
)(uiSaga);
export const searchDirectorStat = withSetQuery2Url(
  actions,
  {
    onGetData: getDataFromSelectors(dataSelectors, prepareDirectorSearchString),
    onSuccess: onSaveSearchToCache(SEARCH_DIRECTOR_COOKIE_KEY)
  }
)(uiSaga);
