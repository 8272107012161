import { createActions } from 'redux-actions';

export const digitalContractActions = createActions({
  CREATE_DIGITAL_CONTRACT: (claimId, values, formik) => ({ claimId, values, formik }),
  CREATE_DIGITAL_CONTRACT_SUCCESS: result => result,
  CREATE_DIGITAL_CONTRACT_FAIL: error => error,

  GET_DIGITAL_CONTRACTS: (claimId, workerOptions) => ({ claimId, workerOptions }),
  GET_DIGITAL_CONTRACTS_SUCCESS: result => result,
  GET_DIGITAL_CONTRACTS_FAIL: error => error,

  GET_DIGITAL_CONTRACT: (claimId, contractId, workerOptions) => (
    { claimId, contractId, workerOptions }
  ),
  GET_DIGITAL_CONTRACT_SUCCESS: result => result,
  GET_DIGITAL_CONTRACT_FAIL: error => error,

  FETCH_DIGITAL_CONTRACT_TEXT: (claimId, workerOptions) => ({ claimId, workerOptions }),
  FETCH_DIGITAL_CONTRACT_TEXT_SUCCESS: result => result,
  FETCH_DIGITAL_CONTRACT_TEXT_FAIL: error => error,

  UPDATE_DIGITAL_CONTRACT: (claimId, contractId, values, formik, onSuccess) => (
    { claimId, contractId, values, formik, onSuccess }
  ),
  UPDATE_DIGITAL_CONTRACT_SUCCESS: result => result,
  UPDATE_DIGITAL_CONTRACT_FAIL: error => error,

  CLEAR_DIGITAL_CONTRACT: () => null,

  SEND_DIGITAL_CONTRACT_TO_EMAIL: (claimId, contractId, values, formik) => (
    { claimId, contractId, values, formik }
  ),
  SEND_DIGITAL_CONTRACT_TO_EMAIL_SUCCESS: contractId => contractId,
  SEND_DIGITAL_CONTRACT_TO_EMAIL_FAIL: error => error,

  DELETE_DIGITAL_CONTRACT: (claimId, contractId, workerOptions, onSuccess) => (
    { claimId, contractId, workerOptions, onSuccess }
  ),
  DELETE_DIGITAL_CONTRACT_SUCCESS: contractId => contractId,
  DELETE_DIGITAL_CONTRACT_FAIL: error => error,

  GENERATE_DIGITAL_CONTRACT_LINK: (claimId, contractId, modalType, workerOptions) => (
    { claimId, contractId, modalType, workerOptions }
  ),
  GENERATE_DIGITAL_CONTRACT_LINK_SUCCESS: (contractId, link) => ({ contractId, link }),
  GENERATE_DIGITAL_CONTRACT_LINK_FAIL: error => error,

  OPEN_DIGITAL_MODAL: bool => bool,
  FETCH_CONTRACT_TEXT_MODE: bool => bool,

  UPDATE_CONTRACT_AND_FETCH_CONTRACT_TEXT: (claimId, values, formik, fieldsDataForError) => (
    { claimId, values, formik, fieldsDataForError }
  ),

  UPDATE_DIGITAL_CONTRACT_LINK: link => link,
  UPDATE_DIGITAL_CONTRACT_LINK_FAIL: link => link,

  createContractFromFiles: (title, files) => ({ title, files }),
  createContractFromFilesSuccess: contract => contract,
  createContractFromFilesFail: error => error,

  copyFilesFromBooking: (contractId, files) => ({ contractId, files }),
  copyFilesFromBookingSuccess: contract => contract,
  copyFilesFromBookingFail: error => error,

  deleteFile: (contractId, fileId) => ({ contractId, fileId }),
  deleteFileSuccess: (contractId, fileId) => ({ contractId, fileId }),
  deleteFileFail: error => error,

  renameFile: (contractId, fileId, publicName) => ({ contractId, fileId, publicName }),
  renameFileSuccess: (contractId, fileId, publicName) => ({ contractId, fileId, publicName }),
  renameFileFail: error => error,

  addFiles: (contractId, files) => ({ contractId, files }),
  addFilesSuccess: () => undefined,
  addFilesFail: error => error,

}, { prefix: 'MT_CLIENT_CLAIM/DIGITAL_CONTRACT' });
