import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import 'react-dates/initialize';

import store from 'init/store';
import { history } from 'init/history';

import 'semantic-ui-css/semantic.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'theme/global';

import { HelmetComponent } from 'services/helmet';
import { ScrollToTop } from 'services/scrollToTop';
import { ErrorBoundary } from 'services/errorBoundary';
import Binotel from 'services/Binotel';
import GoogleTagManagerProvider from 'services/GoogleTagManager';
import { NotificationsProvider } from 'services/toaster';
import { Freshdesk } from 'services/Freshdesk';

import Routes from './routes';

import { ModalDispatcher } from './containers/modalsDispatcher';

import { LocalizationProvider } from './localization/LocalizationProvider';

const root = document.getElementById('root');

const container = createRoot(root);

container.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LastLocationProvider>
        <LocalizationProvider>
          <ErrorBoundary>
            <Routes />
            {!__DEV__ && <GoogleTagManagerProvider />}
            <ModalDispatcher />
            <HelmetComponent />
            <ScrollToTop />
            <Binotel />
            <Freshdesk />
            <NotificationsProvider />
          </ErrorBoundary>
        </LocalizationProvider>
      </LastLocationProvider>
    </ConnectedRouter>
  </Provider>
);
