import { call, select, put } from 'redux-saga/effects';
import { getToken } from 'bus/auth/selectors';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { showToastError, showToastSuccess } from 'services/toaster';

import { loadBookingFromOperator } from 'api/methods/booking';
import { MyError } from 'api/fn/Error';

import { LOAD_BOOKING_FROM_OPERATOR } from '../../constants';

export function* loadBookingFromOperatorSaga({ payload: { entity, callbacks } }) {
  const token = yield select(getToken);
  const { onFinish } = callbacks || {};

  yield put(registerUI(createUi({ loading: true, success: false }), LOAD_BOOKING_FROM_OPERATOR));

  try {
    const { message, status } = yield call(
      loadBookingFromOperator,
      token,
      { bodyParams: entity }
    );

    if (status !== 'success') {
      throw new MyError({ status, body: { message } });
    }

    showToastSuccess(message);
  } catch (error) {
    const { body } = error.msg || {};

    showToastError(body.message);

    yield put(updateUI({ error: true }, LOAD_BOOKING_FROM_OPERATOR));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, LOAD_BOOKING_FROM_OPERATOR));
    onFinish && onFinish();
  }
}
