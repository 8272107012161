import * as R from 'ramda';

import { getDigitalContractsMap } from 'bus/clientClaim/selectors';
import { createUiSelector } from 'bus/ui/helpers';

import { SIGN_MODAL_UI } from './constants';

const domain = R.prop('contractSignModal');

export const getOpenedContractId = R.pipe(
  domain,
  R.prop('openedContractId')
);

export const getActiveStep = R.pipe(
  domain,
  R.prop('activeStep')
);

export const getOpenedDigitalContract = R.converge(
  (contractsMap, contractId) => contractsMap[contractId],
  [
    getDigitalContractsMap,
    getOpenedContractId
  ]
);

export const getSignUI = createUiSelector(SIGN_MODAL_UI);
