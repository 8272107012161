import { takeEvery, put, select, call, fork, take, cancel, race } from 'redux-saga/effects';

import { registerUI, resetUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getClaim, getClaimBookingId } from 'bus/clientClaim/selectors';
import { bookingActions } from 'bus/booking/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getClaimWorker } from 'bus/clientClaim/saga/workers/getClaimWorker';
import { stepersActions } from 'bus/clientClaim/stepers';

import { getInitialDetailsForBookingSaga } from 'components/booking/components/BookingDetail/store/saga';

import { ACTIVE_STEPS } from 'helpers/dictionary';

import { showToastError, showToastSuccess } from 'services/toaster';

import { deleteBooking, initialize, onFillClaim, unMount } from './actions';
import { FILL_CLAIM_UI_KEY, UI_KEY } from './constants';

function* initBookingReservationFullSaga() {
  const id = yield select(getClaimBookingId);

  if (id) {
    yield put(registerUI(createUi({ loading: true }), UI_KEY));

    const [, errorMsg] = yield call(getInitialDetailsForBookingSaga, id);

    if (errorMsg) {
      yield put(updateUI({ message: errorMsg, error: true }, UI_KEY));
    }

    yield put(updateUI({ completed: true, loading: false }, UI_KEY));
  }
}

function* unMountSaga() {
  yield put(resetUI(UI_KEY));
  yield put(bookingActions.clearBooking());
}
function* deleteBookingSaga() {
  const claim = yield select(getClaim);

  yield put(bookingActions.removeClaimFromBooking(
    claim.booking.id,
    claim.id,
  ));

  const [success] = yield race([
    take(bookingActions.removeClaimFromBookingSuccess),
    take(bookingActions.removeClaimFromBookingFail),
  ]);

  if (success) {
    yield put(unMount());
    yield put(clientClaimActions.deleteBookingFromClaim());
  }
}

function* onFillClaimSaga({ payload: { bookingId, claimId } }) {
  yield put(registerUI(createUi({ loading: true }), FILL_CLAIM_UI_KEY));

  yield put(bookingActions.fillClaimFromBooking(bookingId, claimId));

  const [successFill, fail] = yield race([
    take(bookingActions.fillClaimFromBookingSuccess),
    take(bookingActions.fillClaimFromBookingFail)
  ]);

  if (successFill) {
    yield call(getClaimWorker, clientClaimActions.getClaim(claimId));

    yield put(
      stepersActions.setActiveStep(ACTIVE_STEPS.getIn(['CONTRACT', 'marker']))
    );
    showToastSuccess('Договір успішно заповнено');
  } else {
    const message = fail.payload || 'Ошибка сервера';

    yield put(updateUI({ error: true, message }, FILL_CLAIM_UI_KEY));
    showToastError(message);
  }

  yield put(updateUI({ completed: true, loading: false }, FILL_CLAIM_UI_KEY));
}

export default function* watcher() {
  yield takeEvery(initialize, function* () {
    const runningSaga = yield fork(initBookingReservationFullSaga);

    yield take(unMount);

    yield cancel(runningSaga);
  });
  yield takeEvery(unMount, unMountSaga);
  yield takeEvery(deleteBooking, deleteBookingSaga);
  yield takeEvery(onFillClaim, onFillClaimSaga);
}
