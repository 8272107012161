import * as R from 'ramda';

import { removeNull } from 'helpers';

import { BASKET_STATUSES_MAP } from 'api/methods/tourscaner/baskets/constants';

import { LOCALES } from 'localization/constants';
import { BASKET_TEMPLATES_OPTIONS } from './constants';

const PL_LOCALE = 'pl_PL';

const convertLocale = R.cond([
  [R.equals(PL_LOCALE), R.always(LOCALES.PL)],
  [R.T, locale => locale]
]);

export const prepareBasketForUpdate = R.pipe(
  ({ margin, discount, showPriceWithMargin, template, offersReviewsLink, locale, ...rest }) => R.mergeAll([
    rest,
    {
      margin: Number(margin),
      discount: Number(discount),
      show_price_with_margin: showPriceWithMargin,
      template: template === BASKET_TEMPLATES_OPTIONS.DEFAULT ? null : template,
      url_type: offersReviewsLink,
      locale: convertLocale(locale),
    }
  ]),
  R.partialRight(removeNull, [['template']])
);

export const prepareBasketForArchive = ({ status }) => ({
  archive: status === BASKET_STATUSES_MAP.ARCHIVE,
});
