import { assoc, clone } from 'ramda';
import { handleActions } from 'redux-actions';

import {
  setOpenedContractId,
  setActiveStep,
  resetStore,
} from './actions';

const initialState = {
  openedContractId: null,
  activeStep: null
};

export const contractSignModalReducer = handleActions({
  [setOpenedContractId]: (state, { payload: id }) => assoc(
    'openedContractId',
    id,
    state
  ),
  [setActiveStep]: (state, { payload: step }) => assoc(
    'activeStep',
    step,
    state
  ),
  [resetStore]: () => clone(initialState),
}, initialState);
