import { call, put, select } from 'redux-saga/effects';

import { updateUserImage } from 'api/methods/tf/user';
import { getErrorMessage } from 'api/fn/parseErrors';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { tfUsersActions } from '../../../actions';
import { UPDATE_USER_IMG_UI } from '../../../constants';

function* updateUserImageSaga({ payload: { userId, image } }) {
  const token = yield select(getToken);

  try {
    const bodyParams = new FormData();

    bodyParams.append('file', image);

    const { photo_url: photoUrl } = yield call(updateUserImage, token, { pathParams: { userId }, bodyParams });

    yield put(tfUsersActions.updateUserImageSuccess(photoUrl));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(tfUsersActions.updateUserImageFail(error));
  }
}

export const updateUserImageSagaWithUI = withUIWorker(
  [
    tfUsersActions.updateUserImageSuccess,
    tfUsersActions.updateUserImageFail
  ],
  UPDATE_USER_IMG_UI
)(updateUserImageSaga);
