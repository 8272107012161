import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { digitalContractActions } from 'bus/clientClaim/digitalContract';

import { generateDigitalContractLink } from 'api/methods/claim/digitalContract';

const BASE_UI_PATH = ['clientClaim', 'digitalContract', 'link'];

export function* generateDigitalContractLinkWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { claimId, contractId, modalType, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: BASE_UI_PATH,
  })));

  try {
    const result = yield call(
      generateDigitalContractLink,
      token, { pathParams: { id: claimId, contractId }, bodyParams: { method: modalType } }
    );

    yield put(digitalContractActions.updateDigitalContractLink(result.short_url));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 402: {
        message = 'Недостаточно средств на счету';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление. Запрещено если активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = get(body, 'message', 'Заказ не существует');

        break;
      }
      case 406: {
        message = get(body, 'message', 'Не найдены телефонные номера с возможностью SMS для клиента');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));

    yield put(digitalContractActions.updateDigitalContractLinkFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
