import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { generatePath, Link } from 'react-router-dom';

import book from 'routes/book';
import { CustomNavLink } from 'routes/providers/customNavLink';

import { useSubscriptionWithStatuses } from 'containers/molecules/subscription';
import { usePermissionRender } from 'containers/molecules/permission';
import { getCrmTypes } from 'crms/utils';

import NavPointIcon from 'assets/nav-point.svg';
import NavPointIconMobile from 'assets/nav-point-mobile.svg';

import styles from 'components/nav/Menu/styles.scss';

import { isMobile } from 'react-device-detect';

export const Item = memo(({
  activeClassName,
  color,
  exact,
  url,
  content,
  labelCreator,
  subscription: checkAccess,
  allowSubscriptionNullable: nullable,
  permissions,
  labelLink,
  pathToRedirect,
  externalSource = false
}) => {
  const { t } = useTranslation(['USER_MENU', 'TS', 'FIN']);
  const dispatch = useDispatch();

  const { access } = useSubscriptionWithStatuses({ checkAccess, nullable });
  const { is5Stars, isTat, isMt, isNakanikuly } = getCrmTypes();
  const isHavePermissions = usePermissionRender(permissions);

  const isNotTSLink = url !== book.tourscanner.path;

  const onClick = useCallback(e => {
    if (!access) {
      e.preventDefault();
    } else {
      if (pathToRedirect) {
        e.preventDefault();
        dispatch(push(pathToRedirect));
      }
      if (externalSource) {
        e.preventDefault();
        e.stopPropagation();
        window.open(url, '_blank');
      }
    }
  }, [access, pathToRedirect, externalSource, dispatch, url]);

  return (
    <Popup
      hoverable
      content={(
        <>
          {is5Stars && (
            <>
              { t('SUBSCRIPTION_IS_LIMITED') }
              <br />
              { t('EASY_TO_FIX') }
              {' '}
              <Link to={generatePath(book.news.children.single, { id: 1 })}>
                { t('CALL_US') }
              </Link>
              .
            </>
          )}
          {(isTat || isMt) && (
            <>
              <span>
                {t(isNotTSLink ? 'THIS_FUNCTIONAL' : 'PROFESSIONAL_SEARCH')}
                {' '}
              </span>
              <a
                className={styles.link}
                href={isNotTSLink ? '//www.moituristy.ua/ua/public/main/features' : 'https://www.moituristy.ua/ua/public/main/tourscanner'}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t(isNotTSLink ? 'CRM' : 'TS')}
              </a>
            </>
          )}
          {isNakanikuly && (
            <span>
              {t('PROFESSIONAL_SEARCH_NK')}
            </span>
          )}
        </>
      )}
      disabled={access}
      position="bottom center"
      style={{ textAlign: 'center' }}
      trigger={isHavePermissions && (
        <CustomNavLink
          activeClassName={activeClassName}
          className={classnames(styles.navItem, { [styles.disabled]: !access }, styles[color])}
          exact={exact}
          to={url}
          onClick={onClick}
        >
          { t(content) }
          { labelCreator && labelCreator(labelLink) }
          <div className={classnames(styles.navPoint, color)} dangerouslySetInnerHTML={{ __html: isMobile ? NavPointIconMobile : NavPointIcon }} />
        </CustomNavLink>
      )}
    />
  );
});

Item.displayName = 'Item';

Item.propTypes = {
  color: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  labelCreator: PropTypes.func,
  onClick: PropTypes.func,
};
