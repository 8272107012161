import { put, select, call } from 'redux-saga/effects';

import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { getClaimId } from 'bus/clientClaim/selectors';

import { copyFilesFromBooking } from 'api/methods/claim/digitalContract';

import { showToastError } from 'services/toaster';

export function* copyFilesFromBookingSaga({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const claimId = yield select(getClaimId);

  const { contractId, files } = payload;

  try {
    const ids = [];
    const publicNames = [];

    for (const entity of files) {
      const { id, publicName } = entity;

      ids.push(id);
      publicNames.push(publicName);
    }

    const response = yield call(
      copyFilesFromBooking,
      token,
      {
        pathParams: { claimId, contractId },
        bodyParams: {
          document_ids: ids,
          public_names: publicNames,
        }
      }
    );

    yield put(digitalContractActions.copyFilesFromBookingSuccess(response));
  } catch (error) {
    const { body } = error.msg;
    yield put(digitalContractActions.copyFilesFromBookingFail(error));

    showToastError(body?.message);
  }
}
