import { put, takeEvery, call, select, take, race } from 'redux-saga/effects';

import { getDigitalContract } from 'api/methods/claim/digitalContract';

import { showToastError } from 'services/toaster';

import loader from 'containers/modalsDispatcher/loader';

import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';
import { getClaimId, getTouristEmail } from 'bus/clientClaim/selectors';

import { CONTRACT_SIGN_TYPES } from 'dictionary/claim/constants';

import {
  signContract,
  showSignContractModal,
  setActiveStep,
  setOpenedContractId
} from './actions';

import { SIGN_MODAL_UI, STEPS } from './constants';
import { getOpenedContractId, getOpenedDigitalContract } from './selectors';

function* showSignContractModalSaga({ payload: { contractId, callbacks = {} } }) {
  const showModal = loader({
    key: 'CONTRACT_SIGN_MODAL',
    getModal: () => import(/* contract-sign-modal */'../ContractSignModal'),
  });

  const { onSuccess, onInit } = callbacks;

  yield put(setOpenedContractId(contractId));

  const contract = yield select(getOpenedDigitalContract);

  const initialStep = contract.signType
    ? STEPS.SECOND
    : STEPS.FIRST;

  yield put(setActiveStep(initialStep));

  yield put(showModal({
    props: {
      onSuccess
    }
  }));

  onInit && onInit();
}

function* generateSignLink(type) {
  const claimId = yield select(getClaimId);
  const contractId = yield select(getOpenedContractId);

  yield put(digitalContractActions.generateDigitalContractLink(
    claimId,
    contractId,
    type
  ));

  const [isSuccessDigitalContractLink] = yield race([
    take(digitalContractActions.updateDigitalContractLink),
    take(digitalContractActions.updateDigitalContractLinkFail),
  ]);

  if (!isSuccessDigitalContractLink) {
    showToastError('Помилка при генерації посилання на підпис');
  }

  return isSuccessDigitalContractLink;
}

function* refetchDigitalContract() {
  const claimId = yield select(getClaimId);
  const contractId = yield select(getOpenedContractId);
  const token = yield select(getToken);

  try {
    const contract = yield call(
      getDigitalContract,
      token,
      { pathParams: { id: claimId, contractId } }
    );

    yield put(digitalContractActions.updateDigitalContractSuccess(contract));
  } catch (error) {
    showToastError('Помилка при отриманні договору');
  }
}

function* signByEmailType() {
  const claimId = yield select(getClaimId);
  const contractId = yield select(getOpenedContractId);

  const isSuccessGenerateSignLin = yield call(generateSignLink, CONTRACT_SIGN_TYPES.EMAIL);

  if (isSuccessGenerateSignLin) {
    const email = yield select(getTouristEmail);

    yield put(digitalContractActions.sendDigitalContractToEmail(
      claimId, contractId, {
        email,
        email_subject: 'Електронний договір',
        email_text: 'email_sign',
        is_digital: true,
      }
    ));

    const [sendDigitalContractToEmailSuccess] = yield race([
      take(digitalContractActions.sendDigitalContractToEmailSuccess),
      take(digitalContractActions.updateDigitalContractLinkFail),
    ]);

    if (sendDigitalContractToEmailSuccess) {
      yield call(refetchDigitalContract);

      yield put(setActiveStep(STEPS.SECOND));
    } else {
      showToastError('Помилка при відправці договору на email');
    }
  }
}

function* signByPaperType(singDate) {
  const claimId = yield select(getClaimId);
  const contract = yield select(getOpenedDigitalContract);

  yield put(digitalContractActions.updateDigitalContract(
    claimId,
    contract.id,
    {
      is_signed: true,
      signed_date: singDate,
      contract_number: contract.contractNumber,
      sign_method: CONTRACT_SIGN_TYPES.PAPER
    }
  ));

  const [updateDigitalContractSuccess] = yield race([
    take(digitalContractActions.updateDigitalContractSuccess),
    take(digitalContractActions.updateDigitalContractFail),
  ]);

  if (updateDigitalContractSuccess) {
    yield put(setActiveStep(STEPS.SECOND));
  }
}

function* onSignContractSaga({ payload: { signData, onSuccess } }) {
  yield put(registerUI(createUi({ loading: true }), SIGN_MODAL_UI));

  const { signType, singDate } = signData;

  switch (true) {
    case signType === CONTRACT_SIGN_TYPES.EMAIL:
      yield call(signByEmailType);
      break;
    case signType === CONTRACT_SIGN_TYPES.PAPER:
      yield call(signByPaperType, singDate);
      break;
    default:
      const isSuccessGenerateSignLin = yield call(generateSignLink, signType);

      if (isSuccessGenerateSignLin) {
        yield call(refetchDigitalContract);
        yield put(setActiveStep(STEPS.SECOND));
      }
  }

  onSuccess && onSuccess();

  yield put(updateUI({ completed: true, loading: false }, SIGN_MODAL_UI));
}

export function* contractSignModalRootSaga() {
  yield takeEvery(showSignContractModal, showSignContractModalSaga);
  yield takeEvery(signContract, onSignContractSaga);
}
