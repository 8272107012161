import { takeEvery } from 'redux-saga/effects';

import { notifyActions as actions } from 'bus/notify';
import { getTypesWorker } from './workers/getTypes';
import { getNotifiesWorker } from './workers/getNotifies';
import { updateNotifyWorker } from './workers/updateNotify';
import { getDefaultNotifyTemplateWorker, getDefaultNotifyValueWorker } from './workers/getDefaultNotifyValue';
import { prepareEmptyNotifyWorker } from './workers/prepareEmptyNotify';
import { createNotifyWorker, createNotifyWithFormikWorker } from './workers/createNotify';
import { getNotifyValueWorker } from './workers/getNotifyValue';

export const notifyWatchers = Object.freeze({
  * getTypes() {
    yield takeEvery(actions.getTypes, getTypesWorker);
  },
  * getNotifies() {
    yield takeEvery(actions.getNotifies, getNotifiesWorker);
  },
  * updateNotify() {
    yield takeEvery(actions.updateNotify, updateNotifyWorker);
  },
  * getDefaultNotifyTemplate() {
    yield takeEvery(actions.getDefaultNotifyValueTemplate, getDefaultNotifyTemplateWorker);
  },
  * getDefaultNotifyValue() {
    yield takeEvery(actions.getDefaultNotifyValue, getDefaultNotifyValueWorker);
  },
  * getNotifyValueWorker() {
    yield takeEvery(actions.getNotifyValue, getNotifyValueWorker);
  },
  * prepareEmptyNotify() {
    yield takeEvery(actions.prepareEmptyNotify, prepareEmptyNotifyWorker);
  },
  * createNotify() {
    yield takeEvery(actions.createNotify, createNotifyWorker);
  },
  * createNotifyWithFormik() {
    yield takeEvery(actions.createNotifyFull, createNotifyWithFormikWorker);
  },
});
