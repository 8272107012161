import { select, call, put } from 'redux-saga/effects';

import { getBookingData } from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { autoGenerateInvoice } from 'api/methods/accounting';

import { showToastSuccess, showToastError } from 'services/toaster';

import { bookingActions } from '../../../actions';
import { AUTO_GENERATE_INVOICE_UI } from '../../../constants';

function* autoGenerateInvoiceSaga({ payload: onSuccess }) {
  const booking = yield select(getBookingData);
  const token = yield select(getToken);

  try {
    const invoice = yield call(
      autoGenerateInvoice,
      token,
      { bodyParams: {
        booking: booking.id,
        is_incoming: false,
      } }
    );

    yield put(bookingActions.autoGenerateInvoiceSuccess(invoice));
    yield put(bookingActions.getBookingInvoices(booking.id));

    if (onSuccess) {
      yield call(onSuccess, invoice);
    }
    showToastSuccess();
  } catch (error) {
    const { body } = error.msg;

    error.message = body ? body : 'Ошибка сервера';

    yield put(bookingActions.autoGenerateInvoiceFail(error));
    showToastError(body);
  }
}

export const autoGenerateInvoiceSagaWithUI = withUIWorker(
  [
    bookingActions.autoGenerateInvoiceSuccess,
    bookingActions.autoGenerateInvoiceFail
  ],
  AUTO_GENERATE_INVOICE_UI
)(autoGenerateInvoiceSaga);
