import { put, takeEvery, take, race } from 'redux-saga/effects';
import * as R from 'ramda';

import { showToastError } from 'services/toaster';

import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { showSignContractModal } from 'components/modals/ContractSignModal';

import { close, destroy } from 'containers/modalsDispatcher/store/actions';

import { createContract } from './actions';

import { CONTRACT_FILES_MODAL } from './constants';

function* createContractSaga({ payload: contractData }) {
  yield put(registerUI(createUi({ loading: true }), CONTRACT_FILES_MODAL));

  const { title, files } = contractData;

  const newFiles = R.filter(R.prop('file'), files);

  yield put(digitalContractActions.createContractFromFiles(
    title,
    newFiles
  ));

  const [createContractFromFilesSuccess] = yield race([
    take(digitalContractActions.createContractFromFilesSuccess),
    take(digitalContractActions.createContractFromFilesFail),
  ]);

  if (createContractFromFilesSuccess) {
    const { payload: contract } = createContractFromFilesSuccess;

    const filesFromBooking = R.filter(R.prop('id'), files);

    if (filesFromBooking.length) {
      yield put(digitalContractActions.copyFilesFromBooking(
        contract.id,
        filesFromBooking
      ));

      yield race([
        take(digitalContractActions.copyFilesFromBookingSuccess),
        take(digitalContractActions.copyFilesFromBookingFail),
      ]);
    }

    yield put(close(CONTRACT_FILES_MODAL));
    yield put(destroy(CONTRACT_FILES_MODAL));

    yield put(
      showSignContractModal(contract.id)
    );
  } else {
    showToastError('Помилка при створенні договору');
  }

  yield put(updateUI({ completed: true, loading: false }, CONTRACT_FILES_MODAL));
}

export function* contractFilesRootSaga() {
  yield takeEvery(createContract, createContractSaga);
}
