import * as R from 'ramda';

export class GetFormValuesFromSearchStr {
  constructor(searchStr, defaultFormValues) {
    this.searchStr = searchStr;
    this.defaultFormValues = defaultFormValues;

    this.transformOptions = [];
    this.finalResultMapper = R.identity;
  }

  setTransformOptions(transformOptions) {
    this.transformOptions = transformOptions;

    return this;
  }

  setFinalResultMapper(finalResultMapper) {
    this.finalResultMapper = finalResultMapper;

    return this;
  }

  parse() {
    return R.mergeAll([
      this.defaultFormValues,
      this.mappingSrt2Value(),
    ]);
  }

  mappingSrt2Value() {
    const mapper = this.getMapper();
    const allowFields = this.getAllowFields();
    const urlParams = this.getUrlSearchParams();

    return R.call(
      R.pipe(
        R.filter(([key]) => allowFields.has(key)),
        R.map(([key, value]) => [key, mapper(value, key)]),
        R.fromPairs,
        this.finalResultMapper
      ),
      urlParams
    );
  }

  getAllowFields() {
    return new Set(
      R.keys(this.defaultFormValues)
    );
  }

  getMapper() {
    return R.cond([
      ...this.transformOptions,
      [R.T, value => value]
    ]);
  }

  getUrlSearchParams() {
    return [
      ...new URLSearchParams(this.searchStr).entries()
    ];
  }
}
