import { put, race, select, take, all } from 'redux-saga/effects';
import * as R from 'ramda';

import { getTfGroups, getUserFosterKey } from 'bus/auth/selectors';
import { commonActions } from 'bus/common/actions';
import { getOperators } from 'bus/common/selectors';
import { getCommissions } from 'bus/tf/selectors';
import { tfActions } from 'bus/tf/actions';

import { CRM_TYPES } from 'crms/constants';

import { indexBy, pipe, prop } from 'ramda';
import { BOOKING_CALCULATION_TYPES, OPERATORS_BY_TF_GROUP, OPTIONS_CONTROLS_TYPES } from './constants';
import { getJoinUpBookingType, getOperatorBookingType, getOperatorSettingsByTfGroup } from './heplers';

const getPaymentsOptions = ids => R.pipe(
  R.props(R.__, BOOKING_CALCULATION_TYPES),
  R.filter(Boolean),
)(ids);

const generateOptionsType = R.cond([
  [R.equals(OPTIONS_CONTROLS_TYPES.BASE_DEFAULT), R.always(OPTIONS_CONTROLS_TYPES.CHECKBOX)],
  [R.equals(OPTIONS_CONTROLS_TYPES.BASE_PHONE), R.always(OPTIONS_CONTROLS_TYPES.PHONE)],
  [R.T, option => option]
]);

const optionsMapper = R.pipe(
  R.sort((a, b) => a.priority - b.priority),
  R.map(({ id, price, title, currency, active, countries, required, field_type: type }) => ({
    value: id,
    content: currency ? `${title} - ${price}${currency.symbol}` : title,
    props: {
      initialActive: active,
      countriesIds: R.map(({ otpusk_id: countryId }) => countryId, countries),
      required,
      type: generateOptionsType(type),
    },
  }))
);

const getCommissionsByOperatorID = (commissions, operatorId) => R.call(
  R.pipe(
    R.filter(({ operator }) => R.path(['counterparty', 'id'], operator) === operatorId),
    R.map(
      R.applySpec({
        name: R.prop('name'),
        percent: R.prop('percent'),
        comment: R.prop('comment')
      })
    ),
    R.filter(commission => commission.name || commission.percent || commission.comment)
  ),
  commissions
);

const createAlternativeOperatorIdsMap = (alternativeOtpuskIds, options) => R.reduce(
  (acc, alternativeOtpuskId) => R.assoc(alternativeOtpuskId, options, acc),
  {},
  alternativeOtpuskIds
);
const createOperatorsMap = ({ commissions, fosterKey, tfGroups }) => (acc, operator) => {
  const {
    bookingViaCo,
    otpusk_id: otpuskId,
    reservation_conditions: conditionsLink,
    options,
    id,
    isEnabledBooking,
    hasOwnBookingServices,
    title,
    alternative_otpusk_ids: alternativeOtpuskIds = [],
    availableCalculationTypes,
    bookingConditions,
    externalPassportFields,
    disabledPassportFields,
    rateOfConfirmation,
    tfCredentials
  } = operator;

  const settings = getOperatorSettingsByTfGroup(otpuskId, tfGroups);

  const computedOptions = R.mergeAll([
    {
      conditionsLink,
      bookingConditions,
      options: optionsMapper(options),
      commissions: getCommissionsByOperatorID(commissions, id),
      name: __CRM__ === CRM_TYPES.tat ? title : null,
      hasOwnBookingServices,
      paymentTypes: getPaymentsOptions(availableCalculationTypes),
      customBookingTriggerTitle: settings.bookingTitle,
      externalPassportFields,
      disabledPassportFields,
      rateOfConfirmation,
      bookingType: R.includes(otpuskId, OPERATORS_BY_TF_GROUP.JOIN_UP)
        ? getJoinUpBookingType(tfCredentials, bookingViaCo, settings.isEnabledCombinedBooking)
        : getOperatorBookingType(isEnabledBooking),
    },
    bookingViaCo
      ? { fosterAPIParams: { paff: fosterKey ?? 'tat' } }
      : {}
  ]);

  return R.mergeAll([
    acc,
    { [otpuskId]: computedOptions },
    createAlternativeOperatorIdsMap(alternativeOtpuskIds, computedOptions)
  ]);
};

export function* getBookingOperatorsWorker({ payload: callbacks }) {
  const { onSuccess, onFail } = callbacks;

  const [shouldDownloadOperators, shouldDownloadCommission] = yield all([
    select(state => R.isEmpty(getOperators(state))),
    select(state => R.isEmpty(getCommissions(state)))
  ]);

  yield all([
    shouldDownloadOperators && put(commonActions.getOperatorsMt({ with_credentials: true })),
    shouldDownloadCommission && put(tfActions.getCommission({ tourscanner: true }))
  ]);

  const [[getOperatorsSuccess, getOperatorsFail], [getCommissionsSuccess, getCommissionsFail]] = yield all([
    shouldDownloadOperators ? race([
      take(commonActions.getOperatorsMtSuccess),
      take(commonActions.getOperatorsMtFail)
    ]) : [true],
    shouldDownloadCommission ? race([
      take(tfActions.getCommissionSuccess),
      take(tfActions.getCommissionFail)
    ]) : [true]
  ]);

  if (getOperatorsSuccess && getCommissionsSuccess) {
    const operators = yield select(getOperators);
    const commissions = yield select(getCommissions);
    const fosterKey = yield select(getUserFosterKey);
    const tfGroups = yield select(pipe(
      getTfGroups,
      indexBy(prop('id')),
    ));

    const operatorsMap = R.reduce(
      createOperatorsMap({ commissions, fosterKey, tfGroups }),
      {},
      operators
    );

    onSuccess && onSuccess(operatorsMap);
  } else {
    onFail && onFail({
      operatorsFail: R.prop('payload', getOperatorsFail),
      commissionsFail: R.prop('payload', getCommissionsFail),
    });
  }
}
