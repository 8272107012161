import { combineActions } from 'redux-actions';
import moment from 'moment';

import { digitalContractActions as actions } from 'bus/clientClaim/digitalContract';

export const digitalContractState = {
  digitalContracts: [],
  digitalContract: null,
};

export const digitalContractReducer = Object.freeze({
  [actions.getDigitalContractsSuccess]: (state, { payload: result }) => state.set('digitalContracts', result),
  [combineActions(
    actions.createDigitalContractSuccess,
    actions.createContractFromFilesSuccess,
    actions.addFilesSuccess,
  )]: (state, { payload: contract }) => state.update('digitalContracts', contracts => [...contracts, contract]),
  [actions.updateDigitalContractLink]: (state, { payload: url }) => state.update('digitalContract', contract => ({ ...contract, short_url: url })),
  [actions.updateDigitalContractSuccess]: (state, { payload: contract }) => {
    const contractIndex = state
      .get('digitalContracts')
      .findIndex(({ id }) => contract.id === id);

    return state.setIn(['digitalContracts', contractIndex], contract);
  },

  [combineActions(actions.getDigitalContractSuccess, actions.fetchDigitalContractTextSuccess, actions.clearDigitalContract)]: (state, { payload: contract }) => state.set('digitalContract', contract),

  [actions.generateDigitalContractLinkSuccess]: (state, { payload: { contractId, link } }) => {
    const contractIndex = state
      .get('digitalContracts')
      .findIndex(({ id }) => id === contractId);

    return state.setIn(['digitalContracts', contractIndex, 'short_url'], link);
  },
  [actions.deleteDigitalContractSuccess]: (state, { payload: contractId }) => {
    return state.update('digitalContracts', contracts => contracts.filter(({ id }) => id !== contractId));
  },
  [actions.deleteFileSuccess]: (state, { payload: { contractId, fileId } }) => {
    const contractIdx = state.get('digitalContracts').findIndex(({ id }) => contractId === id);

    return state.updateIn(['digitalContracts', contractIdx, 'files'], files => files.filter(({ id }) => id !== fileId));
  },
  [actions.renameFileSuccess]: (state, { payload: { contractId, fileId, publicName } }) => {
    const contractIdx = state.get('digitalContracts').findIndex(({ id }) => contractId === id);
    const fileIdx = state.getIn(['digitalContracts', contractIdx, 'files']).findIndex(({ id }) => fileId === id);

    return state.setIn(['digitalContracts', contractIdx, 'files', fileIdx, 'publicName'], publicName);
  },
  [actions.sendDigitalContractToEmailSuccess]: (state, { payload: contractId }) => {
    const index = state.get('digitalContracts').findIndex(({ id }) => contractId === id);

    return state.setIn(['digitalContracts', index, 'sent_date'], moment().format());
  },
});
