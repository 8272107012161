import React, { memo, useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Search, Icon } from 'semantic-ui-react';
import { generatePath, matchPath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { getIn } from 'immutable';
import { useTranslation } from 'react-i18next';

import { useClients } from 'containers/useAutoComplete/useClients';

import { Button } from 'components/buttons/Button';
import { SearchClaimItem } from 'components/ui/SearchClaimItem';

import { searchClientAndClaimActions } from 'bus/searchClientAndClaim/actions';
import book from 'routes/book';

import SearchIcon from 'assets/icons/materialIcons/person_search_20.svg';

import cx from 'classnames';
import Styles from './styles.scss';

const C = () => {
  const [value, setValue] = useState('');
  const [prevButtonKey, setPrevButtonKey] = useState(0);
  const [open, openSearch] = useState(false);

  const {
    data,
    completed,
    error,
    handleChange,
    loading,
  } = useClients();
  const pathname = useSelector(({ router }) => router.location.pathname, shallowEqual);

  const results = useMemo(() => data.map(item => ({ title: 'ClientClaim', key: item.id || getIn(item, ['client', 'id']), meta: item })), [data]);
  const showNoResults = !data.length && completed && !error;
  const inputEl = useRef(null);

  // methods
  const dispatch = useDispatch();
  const handleResultChange = useCallback((...[, { result }]) => {
    if (result.type === 'button') {
      return;
    }

    result.meta.id && dispatch(push(generatePath(book.clientClaim.children.claim, { clientId: result.meta.client.id, claimId: result.meta.id })));
    !result.meta.id && dispatch(push(generatePath(book.clientClaim.children.profile, { clientId: result.meta.client.id })));

    setValue('');
  }, []);
  const handleSearchOpen = useCallback(() => {
    openSearch(true);
    !value && handleChange('');
  }, [open, value]);
  const handleSearchClose = useCallback(() => openSearch(false), [open]);
  const loadAllResults = useCallback(text => {
    matchPath(pathname, { path: book.client.path, exact: true }) ? dispatch(searchClientAndClaimActions.setFreshQuery({ s: text, claimActive: null })) : dispatch(push(`${book.client.path}?s=${text}`));
  }, [pathname]);
  const handleSubmit = useCallback(({ keyCode, target: { value: text } }) => {
    const IS_ENTER = keyCode === 13;

    if (IS_ENTER) {
      const IS_NOT_ARROWS_KEYS = ![40, 38].includes(prevButtonKey);

      IS_NOT_ARROWS_KEYS && loadAllResults(text);
    }

    setPrevButtonKey(keyCode);
  }, [prevButtonKey]);
  const { t } = useTranslation(['FILTERS', 'CLIENT', 'USER_MENU']);

  const limitResultsWithButton = results.length ? [...results.slice(0, 10), { title: 'Показать все...', key: 'button', type: 'button' }] : [];

  useEffect(() => {
    setValue('');
  }, [pathname]);

  return (
    <Search
      fluid
      className={Styles.root}
      icon={loading
        ? <Icon loading className={Styles.loadingIcon} />
        : (
          <div
            title={t('SEARCH_CLIENTS_AND_ORDERS')}
            className={Styles.searchIcon}
            dangerouslySetInnerHTML={{ __html: SearchIcon }}
          />
        )}
      input={{
        ref: inputEl,
        transparent: true,
        className: cx(Styles.searchField, { [Styles.open]: open }),
      }}
      loading={loading}
      minCharacters={0}
      noResultsMessage={t('NOT_FOUND')}
      placeholder={t('ENTER_WHAT_YOU_SEARCH')}
      resultRenderer={result => {
        if (result.type === 'button') {
          return (
            <Button
              disabled={loading}
              handleClick={() => loadAllResults(value)}
              loading={loading}
            >
              {t('SHOW_ALL')}
              ...
            </Button>
          );
        }

        return (
          <SearchClaimItem claim={result.meta} />
        );
      }}
      results={limitResultsWithButton}
      showNoResults={showNoResults}
      value={value}
      onBlur={handleSearchClose}
      onFocus={handleSearchOpen}
      onKeyDown={handleSubmit}
      onResultSelect={handleResultChange}
      onSearchChange={(...[, { value: text }]) => {
        handleChange(text);
        setValue(text);
      }}
    />
  );
};

C.displayName = 'SearchClient';
export const SearchClient = memo(C);
