import { createActions } from 'redux-actions';

export const notifyActions = createActions({
  GET_TYPES: workerOptions => workerOptions,
  GET_TYPES_SUCCESS: types => types,
  GET_TYPES_FAIL: error => error,

  GET_NOTIFIES: () => undefined,
  GET_NOTIFIES_SUCCESS: notifies => notifies,
  GET_NOTIFIES_FAIL: error => error,

  UPDATE_NOTIFY: (notifyID, values, formik) => ({ notifyID, values, formik }),
  UPDATE_NOTIFY_SUCCESS: notify => notify,
  UPDATE_NOTIFY_FAIL: error => error,

  REFRESH_NOTIFY: (oldID, notify) => ({ oldID, notify }),

  CREATE_NOTIFY: notify => ({ notify }),
  CREATE_NOTIFY_SUCCESS: notify => notify,
  CREATE_NOTIFY_FAIL: error => error,
  CREATE_NOTIFY_FULL: (oldID, notify, formik) => ({ oldID, notify, formik }),

  PREPARE_EDIT_NOTIFY: type => type,
  RESET_EDIT_NOTIFY: () => undefined,

  SET_EDIT_NOTIFY: (type, locale) => ({ type, locale }),

  CHANGE_EDIT_NOTIFY_LOCALE: locale => locale,

  GET_DEFAULT_NOTIFY_VALUE_TEMPLATE: (type, locale, formik) => ({ type, locale, formik }),

  GET_DEFAULT_NOTIFY_VALUE: type => ({ type }),
  GET_DEFAULT_NOTIFY_VALUE_SUCCESS: notify => notify,
  GET_DEFAULT_NOTIFY_VALUE_FAIL: error => error,

  GET_NOTIFY_VALUE: type => ({ type }),
  GET_NOTIFY_VALUE_SUCCESS: notify => notify,
  GET_NOTIFY_VALUE_FAIL: error => error,

  SET_NOTIFY_VALUE: (notify, type) => ({ notify, type }),

  PREPARE_EMPTY_NOTIFY: (type, notify) => ({ type, notify }),
}, { prefix: 'MT_NOTIFY' });
