import { call, select, put } from 'redux-saga/effects';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';
import { bookingActions } from 'bus/booking/actions.js';

import { removeClaimFromBooking } from 'api/methods/booking';

import { showToastError, showToastSuccess } from 'services/toaster';
import { REMOVE_CLAIM_FROM_BOOKING_UI } from '../../constants';

function* removeClaimFromBookingSaga({ payload: { bookingId, claimId, onSuccess } }) {
  const token = yield select(getToken);

  try {
    yield call(removeClaimFromBooking, token, { pathParams: { bookingId, claimId } });

    yield put(bookingActions.removeClaimFromBookingSuccess());
    showToastSuccess();

    if (onSuccess) {
      onSuccess(claimId);
    }
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ?? 'Ошибка сервера';

    showToastError(error.message);
    yield put(bookingActions.removeClaimFromBookingFail(error));
  }
}

export const removeClaimFromBookingSagaWithUI = withUIWorker(
  [
    bookingActions.removeClaimFromBookingSuccess,
    bookingActions.removeClaimFromBookingFail
  ],
  REMOVE_CLAIM_FROM_BOOKING_UI
)(removeClaimFromBookingSaga);
