import { put, select, call } from 'redux-saga/effects';
import { get, getIn, Map } from 'immutable';
import * as R from 'ramda';

// instruments
import { uiActions } from 'bus/ui/actions';
import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { fetchDigitalContractText } from 'api/methods/claim/digitalContract';
import { parseErrorPath } from 'helpers';

const BASE_UI_PATH = ['clientClaim', 'digitalContract', 'fetchText'];

export function* fetchDigitalContractTextWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { claimId, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const { formik } = workerOptions;

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH],
  })));

  try {
    const { html_text: text } = yield call(
      fetchDigitalContractText,
      token,
      { pathParams: { id: claimId } }
    );

    yield put(digitalContractActions.fetchDigitalContractTextSuccess({ contractText: text }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let formikErrors = Map();

    switch (status) {
      case 400: {
        const errorsInstances = [
          { key: 'claim', instance: get(body, 'claim', null) },
          { key: 'locations', instance: get(body, 'locations', null) },
          { key: 'extra', instance: get(body, 'extra', null) },
          { key: 'fields_data', instance: get(body, 'fields_data', null) },
          { key: 'root', instance: get(body, 'root', null) }
        ].filter(item => item.instance);

        if (errorsInstances.length) {
          formikErrors = Map({ ...errorsInstances }).mapEntries(([, { key, instance }]) => {
            let value = null;

            switch (key) {
              case 'locations': {
                value = instance.map(location => {
                  return Map({ ...getIn(location, ['validation', 'violations'], []) }).reduce((results, item) => {
                    return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
                  }, Map());
                });

                break;
              }
              case 'fields_data': {
                error.message = R.converge(R.concat,
                  [
                    R.pipe(R.head, R.path(['entity', 'field', 'label']), R.concat(R.__, ': ')),
                    R.pipe(R.head, R.path(['validation', 'violations']), R.head, R.prop('message'))
                  ]
                )(instance);
                break;
              }
              case 'root': {
                const violations = get(instance, 'violations', {});

                value = Map({ ...violations }).reduce((results, item) => {
                  return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
                }, Map());

                break;
              }
              default: {
                const violations = getIn(instance, ['validation', 'violations'], []);

                value = Map({ ...violations }).reduce((results, item) => {
                  return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
                }, Map());
              }
            }

            return [key, value];
          });
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление. Запрещено если активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = get(body, 'message', 'Заказ не существует');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    !formikErrors.isEmpty() && formik && (yield call(formik.setErrors, formikErrors.toJS()));
    UI && message && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && message && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));

    yield put(digitalContractActions.fetchDigitalContractTextFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
