import React, { memo } from 'react';
import classnames from 'classnames';
import { Popup } from 'semantic-ui-react';

import { Button } from '../Button';

import styles from './styles.scss';

import { TITLE_POSITION } from './constants';

const IconButtonNewPure = ({
  icon,
  label,
  loading,
  disabled,
  theme,
  iconClassName,
  contentClassName,
  title,
  hoverableTitle = false,
  titlePosition = TITLE_POSITION.TOP_CENTER,
  className,
  mouseEnterDelay = 1000,
  mouseLeaveDelay,
  onClick,
  hoveredIcon = true,
  ...buttonProps
}) => {
  return (
    <Button
      {...buttonProps}
      className={classnames(
        styles.root,
        className,
        styles[`theme--${theme}`],
        { [styles.onlyIcon]: !label, },
        { [styles.hoveredIcon]: disabled ? false : hoveredIcon, },
      )}
      disabled={disabled}
      content={(
        <Popup
          hoverable={hoverableTitle}
          disabled={!title}
          content={title}
          position={titlePosition}
          mouseEnterDelay={mouseEnterDelay}
          mouseLeaveDelay={mouseLeaveDelay}
          on="hover"
          trigger={(
            <div className={classnames(
              styles.content,
              contentClassName,
            )}
            >
              {icon && (
                <div
                  className={classnames(
                    styles.icon,
                    { [styles.loading]: loading },
                    iconClassName
                  )}
                  dangerouslySetInnerHTML={{ __html: icon }}
                />
              )}
              {label && (
                <div className={styles.textContainer}>
                  {label}
                </div>
              )}
            </div>
          )}
        />
      )}
      loading={loading}
      handleClick={onClick}
    />
  );
};

export const IconButtonNew = memo(IconButtonNewPure);
