import { call, put, select } from 'redux-saga/effects';

import { deleteUserImage, } from 'api/methods/tf/user';
import { getErrorMessage } from 'api/fn/parseErrors';

import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { tfUsersActions } from '../../../actions';
import { REMOVE_USER_IMG_UI } from '../../../constants';

function* removeUserImageSaga({ payload: userId }) {
  const token = yield select(getToken);

  try {
    yield call(deleteUserImage, token, { pathParams: { userId } });

    yield put(tfUsersActions.removeUserImageSuccess());
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(tfUsersActions.removeUserImageFail(error));
  }
}

export const removeUserImageSagaWithUI = withUIWorker(
  [
    tfUsersActions.removeUserImageSuccess,
    tfUsersActions.removeUserImageFail
  ],
  REMOVE_USER_IMG_UI
)(removeUserImageSaga);
