import { createActions } from 'redux-actions';

const PREFIX = 'BOOKING_COMMON';

export const {
  updateBooking,
  updateBookingWithReload,
  initAutoUpdateBooking,
} = createActions({
  updateBooking: (values, formik, onSuccess) => ({ values, formik, onSuccess }),
  updateBookingWithReload: values => values,
  initAutoUpdateBooking: (values, onSuccess) => ({ values, onSuccess }),
}, { prefix: PREFIX });
