import { map } from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

export const getNews = (token, params = {}) => new BaseApiClass({
  url: 'news',
  method: 'GET',
  ...params,
})._load({ token });

export const getNewsSelections = token => new BaseApiClass({
  url: 'news-selections',
  method: 'GET',
})
  .setParseBodyToClientSchema(map(({ button_colour: buttonColour, ...rest }) => {
    return {
      ...rest,
      buttonColour
    };
  }))
  ._load({ token });

export const getSingleNews = (token, params = {}) => new BaseApiClass({
  url: 'news/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const viewedNews = (token, params = {}) => new BaseApiClass({
  url: 'news/{:id}/viewed',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });
