import BaseApiClass from 'api/BaseApiClass';
import * as R from 'ramda';
import { contractMapper } from './helpers';

export const createDigitalContract = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts',
  method: 'POST',
  ...params,
})
  .setParseBodyToClientSchema(contractMapper)
  ._load({ token });

export const createDigitalContractFromFiles = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts',
  method: 'POST',
  ...params,
})
  .setProccessHeaders(R.dissoc('Content-Type'))
  .setParseBodyToClientSchema(contractMapper)
  ._load({ token });

export const addFiles2Contract = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/digital_contracts/{:contractId}',
  method: 'PUT',
  ...params,
})
  .setProccessHeaders(R.dissoc('Content-Type'))
  .setParseBodyToClientSchema(contractMapper)
  ._load({ token });

export const copyFilesFromBooking = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/digital_contracts/{:contractId}/copy_files',
  method: 'POST',
  ...params,
})
  ._load({ token });

export const deleteContractFile = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/digital_contracts/{:contractId}/files/{:fileId}',
  method: 'DELETE',
  ...params,
})._load({ token });

export const renameContractFile = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/digital_contracts/{:contractId}/rename_file/{:fileId}',
  method: 'PUT',
  ...params,
})._load({ token });

export const getDigitalContracts = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts',
  method: 'GET',
  ...params,
})
  .setParseBodyToClientSchema(R.map(contractMapper))
  ._load({ token });

export const getDigitalContract = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}/digital_contracts/{:contractId}',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = contractMapper;

  return instance._load({ token });
};
export const updateDigitalContract = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}/digital_contracts/{:contractId}',
    method: 'PUT',
    ...params,
  });

  instance.parseBodyToClientSchema = contractMapper;

  return instance._load({ token });
};

export const fetchDigitalContractText = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/html_text',
  method: 'GET',
  ...params,
})._load({ token });

export const sendDigitalContractToEmail = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/{:contractId}/send_email',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteDigitalContract = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/{:contractId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getDiiaKeyFile = (params = {}) => new BaseApiClass({
  outerResource: '/files/tf_files/digital_contracts/{:id}/{:file}',
  method: 'POST',
  ...params,
});

export const generateDigitalContractLink = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/{:contractId}/generate_link',
  method: 'PUT',
  ...params,
})._load({ token });

export const getContractToFormat = (params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/digital_contracts/{:contractId}',
  method: 'GET',
  ...params,
});
