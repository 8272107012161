import * as R from 'ramda';

import { CONTRACT_SIGN_TYPES } from 'dictionary/claim/constants';

import { fileMapper } from '../helpers';

const computedSingProps = ({
  sentDate,
  signedDate,
  diiaSign,
  signMethod
}) => {
  switch (true) {
    case signMethod === CONTRACT_SIGN_TYPES.DIA:
      return {
        isSign: Boolean(R.prop('date_sign', diiaSign)),
        signedDate: R.prop('date_sign', diiaSign),
        sentDate,
        signType: signMethod,
      };
    case signMethod === CONTRACT_SIGN_TYPES.SMS:
    case signMethod === CONTRACT_SIGN_TYPES.EMAIL:
    case signMethod === CONTRACT_SIGN_TYPES.PAPER:
      return {
        isSign: Boolean(signedDate),
        sentDate,
        signedDate,
        signType: signMethod,
      };
    default:
      return {
        signType: null,
        isSign: false,
        sentDate: null,
        signedDate: null,
      };
  }
};

export const contractMapper = ({
  contract_number: contractNumber,
  contract_text: contractText,
  is_signed: isSigned,
  signed_phone_raw: signedPhoneRaw,
  signed_email_raw: signedEmailRaw,
  signed_date: signedDate,
  sent_date: sentDate,
  short_url: shortUrl,
  sign: diiaSign,
  sign_method: signMethod,
  files,
  ...contract
}) => {
  return R.mergeAll([
    contract,
    {
      contractNumber,
      contractText,
      signedPhoneRaw,
      signedEmailRaw,
      shortUrl,
      diiaSign,
      files: R.map(fileMapper, files || []),
    },
    computedSingProps({
      sentDate,
      signedDate,
      diiaSign,
      signMethod
    }),
  ]);
};
