import { takeEvery } from 'redux-saga/effects';

import { tourscanerActions } from 'bus/tourscaner';

import { getClientsWorker } from './workers/getClients';
import { recognizePassportWorker } from './workers/recognizePassport';
import { saveOfferToClaimWorker } from './workers/claim/saveOfferToClaim';
import { logSearchStatisticsWorker } from './workers/logSearchStatistics';
import { sendGAEventWorker } from './workers/sendGAEventWorker';
import { getBasketsWorker } from './workers/baskets/getBaskets';
import { addBasketWorker } from './workers/baskets/addBasket';
import { addOfferToBasketWorker } from './workers/baskets/addOfferToBasket';
import { removeOfferFromBasketWorker } from './workers/baskets/removeOfferFromBasket';
import { updateBasketWorker } from './workers/baskets/updateBasket';
import { getBookingOperatorsWorker } from './workers/getBookingOperatorsWorker';
import { bookingOfferByOperatorWorker } from './workers/bookingOfferByOperator';
import { getCountriesWorker } from './workers/getCountries';
import { copyOfferToBufferSaga } from './workers/copyOfferToBuffer';
import { removeBasketWorker } from './workers/baskets/removeBasket';
import { getManagersSaga } from './workers/getManagers';
import { updateOfferInBasketWorker } from './workers/baskets/updateOfferInBasket';
import { sendBasketWorker } from './workers/baskets/sendBasket';
import { moveOfferInBasketWorker } from './workers/baskets/moveOfferInBasket';
import { addBasketToClaimWorker } from './workers/baskets/addBasketToClaim';
import { copyOfferURLToBufferSaga } from './workers/copyOfferURLToBuffer';
import { getBasketTemplateWorker } from './workers/baskets/getBasketTemplate';
import { getBasketsSimplifiedWorker } from './workers/baskets/getBasketsSimplified';
import { updateBasketStatusWorker } from './workers/baskets/updateBasketStatus';
import { bookingTicketByOperatorWorker } from './workers/bookingTicketByOperator';
import { removeClaimFromBasketWorker } from './workers/baskets/removeClaimFromBasket';
import { copyOfferFromBasketToBufferSaga } from './workers/baskets/copyOfferFromBasketToBuffer';
import { getConnectedBookingsSaga } from './workers/getConnectedBookings';
import { getOffersReviewsLinksWorker } from './workers/baskets/getOffersReviewsLinks';
import { updateCommissionsSaga } from './workers/updateCommissions';
import { getBookingServicesSaga } from './workers/getBookingServices';
import { recalculateBookingServicesSaga } from './workers/recalculateBookingServices';
import { getBasketTemplatesWorker } from './workers/baskets/getBasketTemplates';
import { getSimilarHotelsSaga } from './workers/getSimilarHotels';
import { getIntegrationServicesSaga } from './workers/getIntegrationServices';

export const tourscanerWatchers = Object.freeze({
  * getBookingOperators() {
    yield takeEvery(tourscanerActions.getBookingOperators, getBookingOperatorsWorker);
  },
  * bookingOfferByOperator() {
    yield takeEvery(tourscanerActions.bookingOfferByOperator, bookingOfferByOperatorWorker);
  },
  * getCountries() {
    yield takeEvery(tourscanerActions.getCountries, getCountriesWorker);
  },
  * getClients() {
    yield takeEvery(tourscanerActions.getClients, getClientsWorker);
  },
  * saveOfferToClaim() {
    yield takeEvery(tourscanerActions.saveOfferToClaim, saveOfferToClaimWorker);
  },
  * sendGaEventWatcher() {
    yield takeEvery(tourscanerActions.sendGaEvent, sendGAEventWorker);
  },
  * logSearchStatisticsWatcher() {
    yield takeEvery(tourscanerActions.logSearch, logSearchStatisticsWorker);
  },
  * getBaskets() {
    yield takeEvery(tourscanerActions.getBaskets, getBasketsWorker);
  },
  * getBasketsSimplified() {
    yield takeEvery(tourscanerActions.getBasketsSimplified, getBasketsSimplifiedWorker);
  },
  * addBasket() {
    yield takeEvery(tourscanerActions.addBasket, addBasketWorker);
  },
  * addBasketToClaim() {
    yield takeEvery(tourscanerActions.addBasketToClaim, addBasketToClaimWorker);
  },
  * updateBasket() {
    yield takeEvery(tourscanerActions.updateBasket, updateBasketWorker);
  },
  * updateBasketStatus() {
    yield takeEvery(tourscanerActions.updateBasketStatus, updateBasketStatusWorker);
  },
  * addOfferToBasket() {
    yield takeEvery(tourscanerActions.addOfferToBasket, addOfferToBasketWorker);
  },
  * updateOfferInBasket() {
    yield takeEvery(tourscanerActions.updateOfferInBasket, updateOfferInBasketWorker);
  },
  * sendBasket() {
    yield takeEvery(tourscanerActions.sendBasket, sendBasketWorker);
  },
  * moveOfferInBasket() {
    yield takeEvery(tourscanerActions.moveOfferInBasket, moveOfferInBasketWorker);
  },
  * removeOfferFromBasket() {
    yield takeEvery(tourscanerActions.removeOfferFromBasket, removeOfferFromBasketWorker);
  },
  * removeClaimFromBasket() {
    yield takeEvery(tourscanerActions.removeClaimFromBasket, removeClaimFromBasketWorker);
  },
  * getBasketTemplate() {
    yield takeEvery(tourscanerActions.getBasketTemplate, getBasketTemplateWorker);
  },
  * generateBasketPreview() {
    yield takeEvery(tourscanerActions.generateBasketPreview, getBasketTemplateWorker);
  },
  * removeBasket() {
    yield takeEvery(tourscanerActions.removeBasket, removeBasketWorker);
  },
  * copyOfferToBufferWatcher() {
    yield takeEvery(tourscanerActions.copyOfferToBuffer, copyOfferToBufferSaga);
  },
  * copyOfferFromBasketToBufferWatcher() {
    yield takeEvery(tourscanerActions.copyOfferFromBasketToBuffer, copyOfferFromBasketToBufferSaga);
  },
  * getManagersWatcher() {
    yield takeEvery(tourscanerActions.getManagers, getManagersSaga);
  },
  * copyOfferURLToBufferWatcher() {
    yield takeEvery(tourscanerActions.copyOfferURLToBuffer, copyOfferURLToBufferSaga);
  },
  * bookingTicketByOperatorWatcher() {
    yield takeEvery(tourscanerActions.bookingTicketByOperator, bookingTicketByOperatorWorker);
  },
  * recognizePassportWatcher() {
    yield takeEvery(tourscanerActions.recognizePassport, recognizePassportWorker);
  },
  * getConnectedBookingsWatcher() {
    yield takeEvery(tourscanerActions.getConnectedBookings, getConnectedBookingsSaga);
  },
  * getOffersReviewsLink() {
    yield takeEvery(tourscanerActions.getOffersReviewsLinks, getOffersReviewsLinksWorker);
  },
  * getBasketTemplates() {
    yield takeEvery(tourscanerActions.getBasketTemplates, getBasketTemplatesWorker);
  },
  * updateCommissions() {
    yield takeEvery(tourscanerActions.updateCommissions, updateCommissionsSaga);
  },
  * getBookingServices() {
    yield takeEvery(tourscanerActions.getBookingServices, getBookingServicesSaga);
  },
  * getSimilarHotels() {
    yield takeEvery(tourscanerActions.getSimilarHotels, getSimilarHotelsSaga);
  },
  * recalculateBookingServices() {
    yield takeEvery(tourscanerActions.recalculateBookingServices, recalculateBookingServicesSaga);
  },
  * getIntegrationServices() {
    yield takeEvery(tourscanerActions.getIntegrationServices, getIntegrationServicesSaga);
  },
});
