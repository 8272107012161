import { select, put, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { createBookingService } from 'api/methods/booking/services';

import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';
import { bookingActions } from 'bus/booking/actions';

import { CREATE_BOOKING_SERVICE_UI } from '../../../constants';

const createServiceFromBooking = R.applySpec({
  id: R.prop('id'),
  operator: R.prop('operator'),
  op_claim_id: R.prop('op_claim_id'),
  status: R.prop('status'),
  price: R.path(['cash_flow', 'source_price'])
});

function* createBookingServiceWorker({ payload: { service, callbacks = {} } }) {
  const token = yield select(getToken);

  try {
    const booking = yield call(createBookingService, token, { bodyParams: service });

    yield put(bookingActions.createBookingServiceSuccess(
      createServiceFromBooking(booking)
    ));

    callbacks?.onSuccess();
  } catch (error) {
    yield put(bookingActions.createBookingServiceFail(error));
  }
}

export const createBookingServiceWorkerWithUI = withUIWorker(
  [
    bookingActions.createBookingServiceSuccess,
    bookingActions.createBookingServiceFail
  ],
  CREATE_BOOKING_SERVICE_UI
)(createBookingServiceWorker);
