import * as R from 'ramda';

const domain = R.prop('freshdesk');

export const getShow = R.pipe(
  domain,
  R.prop('isShow'),
);
export const getInitialized = R.pipe(
  domain,
  R.prop('isInitialized'),
);
