import { useDispatch, useSelector } from 'react-redux';

import { getInitialized } from './store/selectors';
import { startBootstrap } from './store/actions';
import { openWidget } from './helpers';

export const freshdeskApi = () => {
  const isInitialized = useSelector(getInitialized);

  const dispatch = useDispatch();

  const onOpenWidget = () => {
    if (!isInitialized) {
      dispatch(startBootstrap(true));

      return;
    }

    openWidget();
  };

  return {
    isInitialized,
    onOpenWidget
  };
};
