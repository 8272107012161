import { createActions } from 'redux-actions';

export const bookingActions = createActions({
  SEARCH: () => undefined,
  searchAdminBooking: () => undefined,
  searchBookingPure: () => undefined,
  SEARCH_SUCCESS: data => data,
  SEARCH_FAIL: error => error,

  SET_QUERY: query => query,
  SET_PAGE: page => page,
  setAdminOrder: order => order,
  setUserOrder: order => order,

  GET_BOOKING: bookingId => ({ bookingId }),
  GET_BOOKING_SUCCESS: booking => booking,
  GET_BOOKING_FAIL: error => error,

  getBookingCash: bookingId => bookingId,
  getBookingCashSuccess: cash => cash,
  getBookingCashFail: error => error,

  mergeBookingData: data => data,

  getDocuments: id => id,
  getDocumentsSuccess: documents => documents,
  getDocumentsFail: error => error,

  addDocuments: documents => documents,
  addDocumentsSuccess: documents => documents,
  addDocumentsFail: error => error,

  updateDocument: (id, document) => ({ id, document }),
  updateDocumentSuccess: (documentId, document) => ({ documentId, document }),
  updateDocumentFail: error => error,

  deleteDocument: id => id,
  deleteDocumentSuccess: id => id,
  deleteDocumentFail: error => error,

  CLEAR_BOOKING: () => undefined,
  clearBookings: () => undefined,

  UPDATE_BOOKING: (values, formik) => ({ values, formik }),
  UPDATE_BOOKING_SUCCESS: booking => booking,
  UPDATE_BOOKING_FAIL: error => error,

  CREATE_BOOKING: (values, formik) => ({ values, formik }),
  CREATE_BOOKING_SUCCESS: () => undefined,
  CREATE_BOOKING_FAIL: error => error,

  UPDATE_BOOKING_FROM_LIST: (values, bookingId, formik) => ({ values, bookingId, formik }),
  UPDATE_BOOKING_FROM_LIST_SUCCESS: booking => booking,
  UPDATE_BOOKING_FROM_LIST_FAIL: error => error,

  DELETE_BOOKING: (bookingId, workerOptions) => ({ bookingId, workerOptions }),
  DELETE_BOOKING_SUCCESS: () => undefined,
  DELETE_BOOKING_FAIL: () => undefined,

  CREATE_CLAIM_FROM_BOOKING: (bookingId, params, workerOptions) => (
    { bookingId, params, workerOptions }
  ),
  CREATE_CLAIM_FROM_BOOKING_SUCCESS: result => result,
  CREATE_CLAIM_FROM_BOOKING_FAIL: error => error,

  FILL_CLAIM_FROM_BOOKING: (bookingId, claimId, workerOptions, onSuccess) => (
    { bookingId, claimId, workerOptions, onSuccess }
  ),
  FILL_CLAIM_FROM_BOOKING_SUCCESS: success => success,
  FILL_CLAIM_FROM_BOOKING_FAIL: error => error,

  removeClaimFromBooking: (bookingId, claimId, onSuccess) => (
    { bookingId, claimId, onSuccess }
  ),
  removeClaimFromBookingSuccess: () => undefined,
  removeClaimFromBookingFail: error => error,

  getMessagesTemplates: () => undefined,
  getMessagesTemplatesSuccess: templates => templates,
  getMessagesTemplatesFail: error => error,

  CREATE_AND_FILL_CLAIM: (bookingId, params, onSuccess) => ({ bookingId, params, onSuccess }),
  CREATE_AND_FILL_CLAIM_SUCCESS: success => success,
  CREATE_AND_FILL_CLAIM_FAIL: error => error,

  MANAGING_BOOKING_SUCCESS: result => result,
  CLEAR_MANAGING_BOOKING: () => ({}),

  GET_BOOKING_CHANGES: (bookingId, query) => ({ bookingId, query }),
  GET_BOOKING_CHANGES_SUCCESS: changes => changes,
  GET_BOOKING_CHANGES_FAIL: error => error,

  MARK_BOOKING_VIEWED: (bookingId, workerOptions) => ({ bookingId, workerOptions }),
  MARK_BOOKING_VIEWED_SUCCESS: () => undefined,
  MARK_BOOKING_VIEWED_FAIL: error => error,

  REFRESH_BOOKING: bookingId => ({ bookingId }),
  REFRESH_BOOKING_SUCCESS: () => undefined,
  REFRESH_BOOKING_FAIL: error => error,

  SAVE_BOOKING: bookingId => ({ bookingId }),

  GET_BOOKINGS_STAT: () => undefined,
  GET_BOOKINGS_STAT_SUCCESS: statistic => statistic,
  GET_BOOKINGS_STAT_FAIL: error => error,

  loadBookingFromOperator: (entity, callbacks) => ({ entity, callbacks }),

  // bookingMassages

  GET_BOOKING_MESSAGES: bookingID => bookingID,
  GET_BOOKING_MESSAGES_SUCCESS: messages => messages,
  GET_BOOKING_MESSAGES_FAIL: error => error,

  CREATE_BOOKING_MESSAGE: (values, formik, onSuccess) => ({ values, formik, onSuccess }),
  CREATE_BOOKING_MESSAGE_SUCCESS: message => message,
  CREATE_BOOKING_MESSAGE_FAIL: error => error,

  DELETE_BOOKING_MESSAGE: messageId => messageId,
  DELETE_BOOKING_MESSAGE_SUCCESS: messageId => messageId,

  GET_MESSAGES_COUNT_UNREAD: () => undefined,
  GET_MESSAGES_COUNT_UNREAD_SUCCESS: count => count,

  UPDATE_MESSAGE_VIEWED: messageId => messageId,
  UPDATE_MESSAGE_VIEWED_SUCCESS: (view, messageId) => ({ view, messageId }),

  updateAllMessagesViews: excludedIds => excludedIds,

  removeMessageViewed: (messageId, viewId, onSuccess) => ({ viewId, messageId, onSuccess }),
  removeMessageViewedSuccess: (messageId, viewId, onSuccess) => ({ viewId, messageId, onSuccess }),

  // bookingMoney
  updateCash: cash => cash,
  updateCashSuccess: cash => cash,
  updateCashFail: error => error,

  updateBookingWithCash: (cash, booking, callbacks) => ({ cash, booking, callbacks }),

  // services

  CREATE_BOOKING_SERVICES: (values, id, bookingId, formik) => ({ values, id, bookingId, formik }),
  CREATE_BOOKING_SERVICES_SUCCESS: (service, id) => ({ service, id }),
  CREATE_BOOKING_SERVICES_FAIL: error => error,

  UPDATE_BOOKING_SERVICES: (values, id, bookingId, formik) => ({ values, id, bookingId, formik }),
  UPDATE_BOOKING_SERVICES_SUCCESS: service => service,
  UPDATE_BOOKING_SERVICES_FAIL: error => error,

  DELETE_BOOKING_SERVICES: (bookingId, servicesId) => ({ bookingId, servicesId }),
  DELETE_BOOKING_SERVICES_SUCCESS: servicesId => servicesId,
  DELETE_BOOKING_SERVICES_FAIL: error => error,

  createBookingService: (service, callbacks) => ({ service, callbacks }),
  createBookingServiceSuccess: service => service,
  createBookingServiceFail: error => error,

  // invoices

  GET_BOOKING_INVOICES: id => id,
  GET_BOOKING_INVOICES_SUCCESS: invoices => invoices,
  GET_BOOKING_INVOICES_FAIL: error => error,

  CREATE_BOOKING_INVOICE_SUCCESS: invoice => invoice,
  DELETE_BOOKING_INVOICE_SUCCESS: invoiceId => invoiceId,

  CREATE_OPERATOR_INVOICE: (values, id, formik) => ({ values, id, formik }),
  CREATE_OPERATOR_INVOICE_SUCCESS: invoice => invoice,
  CREATE_OPERATOR_INVOICE_FAIL: error => error,

  UPDATE_OPERATOR_INVOICE: (values, id, bookingId, formik) => ({ values, id, bookingId, formik }),
  UPDATE_OPERATOR_INVOICE_SUCCESS: invoice => invoice,
  UPDATE_OPERATOR_INVOICE_FAIL: error => error,

  showCreateInvoiceModal: defaultInvoiceData => defaultInvoiceData,
  autoGenerateInvoice: onSuccess => onSuccess,
  autoGenerateInvoiceSuccess: invoice => invoice,
  autoGenerateInvoiceFail: () => undefined,

  setAktToInvoice: (invoiceId, akt) => ({ invoiceId, akt }),

  // payments

  GET_BOOKING_PAYMENTS: id => id,
  GET_BOOKING_PAYMENTS_SUCCESS: payments => payments,
  GET_BOOKING_PAYMENTS_FAIL: error => error,

  CREATE_BOOKING_PAYMENT_SUCCESS: payment => payment,
  UPDATE_BOOKING_PAYMENT_SUCCESS: payment => payment,
  DELETE_BOOKING_PAYMENT_SUCCESS: paymentId => paymentId,

  // tourists

  CREATE_BOOKING_TOURIST: (values, id, bookingId, formik, onSuccess) => ({ values, id, bookingId, formik, onSuccess }),
  CREATE_BOOKING_TOURIST_SUCCESS: (tourist, id) => ({ tourist, id }),
  CREATE_BOOKING_TOURIST_FAIL: error => error,

  UPDATE_BOOKING_TOURIST: (values, id, bookingId, formik, onSuccess) => ({ values, id, bookingId, formik, onSuccess }),
  UPDATE_BOOKING_TOURIST_SUCCESS: tourist => tourist,
  UPDATE_BOOKING_TOURIST_FAIL: error => error,

  DELETE_BOOKING_TOURIST: (bookingId, touristId) => ({ bookingId, touristId }),
  DELETE_BOOKING_TOURIST_SUCCESS: touristId => touristId,
  DELETE_BOOKING_TOURIST_FAIL: error => error,

  // schedules

  GET_BOOKING_SCHEDULES: bookingId => bookingId,
  GET_BOOKING_SCHEDULES_SUCCESS: schedules => schedules,
  GET_BOOKING_SCHEDULES_FAIL: error => error,

  createBookingSchedules: schedules => schedules,
  createBookingSchedulesSuccess: schedules => schedules,
  createBookingSchedulesFail: error => error,

  createSchedulesFromTemplate: templateId => templateId,
  createSchedulesFromTemplateSuccess: schedules => schedules,
  createSchedulesFromTemplateFail: error => error,

  updateBookingSchedules: schedules => schedules,
  updateBookingSchedulesSuccess: schedules => schedules,
  updateBookingSchedulesFail: error => error,

  // end2endBooking

  calculateEnd2EndBooking: () => undefined,
  bookingEnd2EndBooking: values => values,
  setEnd2EndBookingData: data => data,

  setRateHash: hash => hash,
  getBookingViews: bookingId => bookingId,
  setBookingViews: views => views,
}, { prefix: 'MT_BOOKING' });
