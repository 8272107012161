import { newInstance } from 'localization';

import { getCrmTypes } from 'crms/utils';
import { BASKET_TEMPLATES_OPTIONS } from './constants';

// eslint-disable-next-line require-yield
export function* getBasketTemplatesWorker({ payload: callbacks }) {
  const { onSuccess, onFail } = callbacks;

  const { isMultitour } = getCrmTypes();

  try {
    onSuccess([
      ...isMultitour
        ? [
          { content: newInstance.t('TS:BASKET_TEMPLATES:MULTITOUR_STRICT'), value: BASKET_TEMPLATES_OPTIONS.MULTITOUR_STRICT },
          { content: newInstance.t('TS:BASKET_TEMPLATES:MULTITOUR_COMPACT'), value: BASKET_TEMPLATES_OPTIONS.MULTITOUR_COMPACT },
          { content: newInstance.t('TS:BASKET_TEMPLATES:MULTITOUR_TROPIC'), value: BASKET_TEMPLATES_OPTIONS.MULTITOUR_TROPIC },
          { content: newInstance.t('TS:BASKET_TEMPLATES:MULTITOUR_COMBINED'), value: BASKET_TEMPLATES_OPTIONS.MULTITOUR_COMBINED },
        ]
        : [],
      { content: newInstance.t('TS:BASKET_TEMPLATES:DEFAULT_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.DEFAULT },
      { content: newInstance.t('TS:BASKET_TEMPLATES:STRICT_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.STRICT },
      { content: newInstance.t('TS:BASKET_TEMPLATES:COMPACT_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.COMPACT },
      { content: newInstance.t('TS:BASKET_TEMPLATES:MINIMALISM_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.MINIMALISM },
      { content: newInstance.t('TS:BASKET_TEMPLATES:MINIMALISM_DARK_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.MINIMALISM_DARK },
      { content: newInstance.t('TS:BASKET_TEMPLATES:CITRUS_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.CITRUS },
      { content: newInstance.t('TS:BASKET_TEMPLATES:SERENITY_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.SERENITY },
      { content: newInstance.t('TS:BASKET_TEMPLATES:CHILD_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.CHILD },
      { content: newInstance.t('TS:BASKET_TEMPLATES:NIGHT_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.NIGHT },
      { content: newInstance.t('TS:BASKET_TEMPLATES:BEACH_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.BEACH },
      { content: newInstance.t('TS:BASKET_TEMPLATES:TROPIC_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.TROPIC },
      { content: newInstance.t('TS:BASKET_TEMPLATES:DAWN_TEMPLATE_OPTION'), value: BASKET_TEMPLATES_OPTIONS.DAWN },
    ]);
  } catch (error) {
    onFail && onFail(error);
  }
}
