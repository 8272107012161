import { put, select, call } from 'redux-saga/effects';

import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';
import { getClaimId } from 'bus/clientClaim/selectors';

import { addFiles2Contract } from 'api/methods/claim/digitalContract';

import { showToastError } from 'services/toaster';

export function* addFiles2ContractSaga({ payload: { contractId, files } }) {
  const token = yield select(getToken);
  const claimId = yield select(getClaimId);

  try {
    const bodyParams = new FormData();

    for (const file of files) {
      bodyParams.append('files[]', file);
    }

    const response = yield call(
      addFiles2Contract,
      token,
      { pathParams: { claimId, contractId }, bodyParams }
    );

    yield put(digitalContractActions.addFilesSuccess(response));
  } catch (error) {
    const { body } = error.msg || {};

    yield put(digitalContractActions.addFilesFail(error));

    showToastError(body?.message);
  }
}

export default withUIWorker(
  [digitalContractActions.addFilesSuccess, digitalContractActions.addFilesFail],
  'ADD_FILES_TO_CONTRACT_UI',
)(addFiles2ContractSaga);
