import { call } from 'redux-saga/effects';
import { newInstance } from 'localization';
import { generatePath } from 'react-router-dom';

import book from 'routes/book';

import { INTEGRATION_SERVICES } from './constants';

export const getPriceOfNonArrivalInsurance = (price, percent) => Math.round(price * percent / 100);

export function* getIntegrationServicesSaga({ payload: { callbacks, offer } }) {
  const { onSuccess, onFail } = callbacks;

  try {
    yield call(
      onSuccess,
      [
        {
          id: INTEGRATION_SERVICES.NON_ARRIVAL_INSURANCE,
          name: newInstance.t('TS:INTEGRATION_SERVICES:NON_ARRIVAL_INSURANCE_LABEL'),
          detailsLink: generatePath(book.news.children.single, { id: 265 }),
          price: getPriceOfNonArrivalInsurance(offer.price[offer.currencyLocal], 2),
        },
      ]
    );
  } catch (error) {
    onFail && onFail(error);
  }
}
