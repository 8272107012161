import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButtonNew } from 'components/buttons/IconButtonNew';

import { freshdeskApi } from 'services/Freshdesk';

import QuestionIcon from './img/reqTat.svg';

import Styles from './styles.scss';

const FreshdeskButton = () => {
  const { t } = useTranslation('USER_MENU');

  const { onOpenWidget } = freshdeskApi();

  return (
    <IconButtonNew
      className={Styles.quest}
      iconClassName={Styles.questIcon}
      icon={QuestionIcon}
      title={t('REQUEST_TAT')}
      onClick={onOpenWidget}
    />
  );
};

export default memo(FreshdeskButton);
