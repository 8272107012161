export const UPDATE_BOOKING_WITH_CASH = 'UPDATE_BOOKING_WITH_CASH';
export const REFRESH_BOOKING_UI = ['booking', 'refreshBooking'];
export const BOOKING_END2END_BOOKING_UI = 'BOOKING_END2END_BOOKING';
export const CALCULATE_END2END_BOOKING = 'CALCULATE_END2END_BOOKING';
export const GET_BOOKING_VIEWS_US = 'GET_BOOKING_VIEWS';
export const AUTO_GENERATE_INVOICE_UI = 'AUTO_GENERATE_INVOICE_UI';
export const CREATE_BOOKING_SERVICE_UI = 'CREATE_BOOKING_SERVICE_UI';
export const LOAD_BOOKING_FROM_OPERATOR = 'LOAD_BOOKING_FROM_OPERATOR';
export const GET_MESSAGES_TEMPLATES_UI = 'GET_MESSAGES_TEMPLATES';
export const REMOVE_CLAIM_FROM_BOOKING_UI = 'REMOVE_CLAIM_FROM_BOOKING_UI';
