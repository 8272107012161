import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

import { basketsMapper } from '../../tourscaner/baskets/helpers';

import { fileMapper } from '../helpers';

export const getClaim = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = ({ selections, booking, ...rest }) => ({
    ...rest,
    baskets: R.pipe(
      basketsMapper,
      R.reverse
    )(selections),
    booking: booking ? R.over(
      R.lensProp('documents'),
      R.map(fileMapper),
      booking
    ) : null,
  });

  return instance._load({ token });
};

export const getClaimSimplified = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/simplified',
  method: 'GET',
  ...params,
})._load({ token });
