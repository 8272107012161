import { put, select, call } from 'redux-saga/effects';

import { deleteContractFile } from 'api/methods/claim/digitalContract';

import { getToken } from 'bus/auth/selectors';
import { createUi } from 'bus/ui/helpers';
import { registerUI, updateUI } from 'bus/ui/actions';
import { getClaimId } from 'bus/clientClaim/selectors';

import { showToastError } from 'services/toaster';

import { digitalContractActions } from '../../../actions';

import { createDeleteFileUIKey } from './helpers';

export function* deleteFileSaga({ payload: { contractId, fileId } }) {
  const uiKey = createDeleteFileUIKey(fileId);
  const claimId = yield select(getClaimId);

  yield put(registerUI(createUi({ loading: true }), uiKey));

  try {
    const token = yield select(getToken);

    yield call(
      deleteContractFile,
      token,
      { pathParams: { claimId, fileId, contractId } }
    );

    yield put(digitalContractActions.deleteFileSuccess(contractId, fileId));
  } catch (error) {
    yield put(digitalContractActions.deleteFileFail());

    showToastError(error.msg?.body?.message);
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}
