export const BASKET_TEMPLATES_OPTIONS = {
  DEFAULT: 'default',
  STRICT: 'strict',
  COMPACT: 'compact',
  MINIMALISM: 'minimalism',
  MINIMALISM_DARK: 'minimalism-dark',
  CITRUS: 'citrus',
  SERENITY: 'serenity',
  CHILD: 'child',
  NIGHT: 'night',
  BEACH: 'beach',
  TROPIC: 'tropic',
  DAWN: 'dawn',
  MULTITOUR_STRICT: 'multitour_strict',
  MULTITOUR_COMPACT: 'multitour_compact',
  MULTITOUR_TROPIC: 'multitour_tropic',
  MULTITOUR_COMBINED: 'multitour_combined',
};
