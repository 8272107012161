import { select, put, call, race, take } from 'redux-saga/effects';

import { notifyActions } from 'bus/notify';

import { createNotify } from 'api/methods/tf/notify';

export function* createNotifyWorker({ payload }) {
  const { notify } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const id = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  try {
    const response = yield call(createNotify, token, { pathParams: { id }, bodyParams: notify });

    yield put(notifyActions.createNotifySuccess(response));
  } catch (error) {
    yield put(notifyActions.createNotifyFail(error));
  }
}

export function* createNotifyWithFormikWorker({ payload }) {
  const { oldID, notify, formik } = payload;

  yield put(notifyActions.createNotify(notify));

  const [success, fail] = yield race([
    take(notifyActions.createNotifySuccess),
    take(notifyActions.createNotifyFail)
  ]);

  if (success) {
    yield put(notifyActions.refreshNotify(oldID, success.payload));

    yield call(formik.resetForm);
    yield call(formik.setStatus, { updateNotify: { success: true } });
  }

  if (fail) {
    yield call(formik.setStatus, { updateNotify: { error: true, message: 'Ошибка сервера' } });
  }

  yield call(formik.setSubmitting, false);
}
