export const openWidget = () => window.FreshworksWidget('open');

export const bootstrap = userData => {
  window.FreshworksWidget('hide', 'launcher');
  window.FreshworksWidget('identify', 'ticketForm', userData);
  window.FreshworksWidget('prefill', 'ticketForm', {
    priority: 2
  });
  window.FreshworksWidget('hide', 'ticketForm', ['product_id', 'custom_fields.cf_reference_number']);

  openWidget();
};
