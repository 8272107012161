import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

import { digitalContractActions } from 'bus/clientClaim/digitalContract';

import { updateDigitalContract } from 'api/methods/claim/digitalContract';

import { showToastError } from 'services/toaster';

export function* updateDigitalContractWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { claimId, contractId, values, formik, onSuccess } = payload;

  try {
    const response = yield call(
      updateDigitalContract,
      token,
      { pathParams: { id: claimId, contractId }, bodyParams: values }
    );

    yield put(digitalContractActions.updateDigitalContractSuccess(response));
    yield put(digitalContractActions.getDigitalContractSuccess(response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление. Запрещено если активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = get(body, 'message', 'Заказ не существует');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(digitalContractActions.updateDigitalContractFail(error));
    showToastError(body.message || message);
  } finally {
    formik && (yield call(formik.setSubmitting, false));
    onSuccess && onSuccess();
  }
}
