import { createSelector } from 'reselect';
import * as R from 'ramda';

import { CURRENCIES } from 'helpers/currency';

const EMPTY_OBJ = {};
const EMPTY_LIST = [];

export const domain = ({ auth }) => auth;

export const getInitialized = R.pipe(
  domain,
  auth => auth.get('initialized')
);

export const getLocale = createSelector(domain, auth => auth.get('locale'));
export const getIsAuth = createSelector(domain, auth => auth.get('isAuthenticate'));
export const getProfile = createSelector(domain, auth => auth.get('profile'));
export const getLimitClientView = R.pipe(getProfile, R.prop('limit_client_views'));
export const getChartersCommissions = R.pipe(getProfile, R.prop('commission'));
export const getToken = createSelector(domain, auth => auth.get('token'));

const getOtpuskSettings = R.pipe(
  domain,
  auth => auth.get('otpusk')
);

const getOtpuskTokens = R.pipe(
  getOtpuskSettings,
  settings => settings.get('token')
);

export const getOtpuskToken = R.pipe(
  getOtpuskTokens,
  R.prop('access_token')
);

const getChartersToken = R.pipe(
  getOtpuskTokens,
  R.prop('charters_access_token')
);

export const getChartersTokenWithDefault = R.converge(
  R.or,
  [getChartersToken, getOtpuskToken]
);

export const getUserLocale = createSelector(getProfile, R.prop('locale'));

export const getUserOrTfLocale = createSelector([getUserLocale, getLocale], R.or);
export const getTelegramData = R.pipe(getProfile, profile => profile.telegram_data);
export const isActiveTelegram = R.pipe(getTelegramData, data => data && data.is_active);
export const getUserId = R.pipe(getProfile, R.prop('id'));
export const getUserName = R.pipe(getProfile, R.prop('username'));
export const getUserTf = R.pipe(getProfile, R.prop('tf'));
export const getUserFinancesPermission = createSelector(getProfile, R.prop('allowed_view_finances'));
export const getUserRoles = R.pipe(getProfile, R.prop('roles'));

const DIRECTOR_ROLE = 'director';

export const getDirectorRoles = createSelector(getUserRoles, R.pipe(
  R.map(({ name }) => name.toLowerCase()),
  R.includes(DIRECTOR_ROLE)
));
export const getScenarios = createSelector(getUserTf, R.propOr(EMPTY_LIST, 'scenarios'));
export const getUserTfCity = createSelector(getUserTf, R.propOr(EMPTY_OBJ, 'city'));
export const getUserTimezone = R.pipe(
  getUserTf,
  R.prop('timezone')
);
export const getUserTfId = R.pipe(getUserTf, R.prop('id'));
export const getUserTfCustomization = R.pipe(getUserTf, R.propOr(EMPTY_OBJ, 'customization'));
export const getUserTfCurator = createSelector(getUserTf, R.propOr(EMPTY_OBJ, 'curator'));
export const getUserTfCounterparties = createSelector(getUserTf, R.propOr(EMPTY_LIST, 'counterparties'));

export const getUserTfFirstCounterparty = createSelector(getUserTfCounterparties, R.find(({ is_deleted: isDeleted }) => !isDeleted));
export const getTfActiveCounterparties = R.pipe(
  getUserTfCounterparties,
  R.filter(({ is_deleted: isDeleted }) => !isDeleted)
);
export const getUserTfFirstCounterpartyID = createSelector(getUserTfFirstCounterparty, R.prop('id'));
export const getUserTfFirstCounterpartyContracts = createSelector(getUserTfFirstCounterparty, R.prop('contracts'));

export const getUserSubscriptions = createSelector(getUserTf, R.prop('subscriptions'));
export const getTfSubscription = createSelector(getUserTf, R.prop('subscription'));

export const getTfScenarios = createSelector(getUserTf, R.propOr(EMPTY_LIST, 'scenarios'));
export const getTfGroups = R.pipe(getUserTf, R.prop('tf_groups'));

export const getTfFacebooks = createSelector(getUserTf, R.prop('facebooks'));
const getTfPhones = R.pipe(getUserTf, R.prop('phones'));
const getTfEmail = R.pipe(getUserTf, R.prop('email'));
const getTfLogo = R.pipe(getUserTf, R.prop('logo_image_url'));

export const getIsFilledProfileInfo = R.converge(
  (phones, email, logo) => Boolean(
    !R.isEmpty(phones) && email && logo
  ),
  [getTfPhones, getTfEmail, getTfLogo]
);

export const isSetLocale = createSelector(
  getUserLocale,
  (_, { locale }) => locale,
  R.equals
);

export const autoSubscribe = createSelector(
  getUserSubscriptions, subscriptions => R.call(
    R.any(R.prop('isActive')),
    subscriptions
  )
);

export const getOtpuskRegionID = createSelector(
  getUserTfCity, ({ otpuskRegionId }) => otpuskRegionId
);
export const getUserTfCountry = R.pipe(
  getUserTfCity,
  R.prop('country')
);

export const getUserTfCurrencyCode = R.pipe(
  getUserTfCountry,
  R.prop('currencyCode')
);
export const getUserTfCurrency = createSelector(
  getUserTfCurrencyCode, currencyCode => CURRENCIES.find(({ code }) => code === currencyCode)
);
export const getUserTfCurrencySymbol = createSelector(getUserTfCurrency, R.prop('symbol'));
export const getUserCurrencyCode = createSelector(getUserTfCurrency, R.prop('code'));
export const getUserTfCountryCode = createSelector(getUserTfCountry, R.prop('code'));
export const getIsUkraineTf = createSelector(getUserTfCountryCode, code => code === 'ua');
export const isAllowUseTourscaner = createSelector(getUserTfCountryCode, code => R.includes(code, ['ua', 'by', 'lv', 'lt', 'ee', 'uz', 'pl', 'md', 'kz']));

export const getCollectionsAPISettings = createSelector(
  getProfile, ({ collectionsAPI }) => collectionsAPI
);

const getUserTfActiveUsersCount = createSelector(getUserTf, R.prop('activeUsersCount'));
const getTfCountMan = createSelector(getTfSubscription, R.prop('cnt_man'));
const isTrial = createSelector(getTfSubscription, R.prop('is_trial'));

export const isDisableTSSearch = createSelector(
  getUserTfActiveUsersCount,
  getTfCountMan,
  isTrial,
  (activeUsers, limitUsers, trial) => activeUsers > limitUsers && !trial
);

export const getUserFosterKey = state => {
  return getProfile(state)?.foster_key;
};
