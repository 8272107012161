import React, { memo, useState, useCallback } from 'react';
import { Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { copyToBuffer } from 'helpers';

export const CopyWrapper = memo(({ copyText, message, children, timeout }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('GLOBAL');

  const timer = useCallback(() => setTimeout(() => setOpen(false), timeout), []);
  const openPopup = useCallback(() => {
    setOpen(true);
    copyToBuffer(copyText).then(timer);
  }, [copyText]);
  const closePopup = useCallback(() => {
    setOpen(false);
    clearTimeout(timer);
  }, []);

  return (
    <Popup
      content={<p>{ message || t('COPY_SUCCESS') }</p>}
      on="click"
      open={open}
      position="top center"
      trigger={children}
      onClose={closePopup}
      onOpen={openPopup}
    />
  );
});

CopyWrapper.displayName = 'CopyWrapper';
CopyWrapper.defaultProps = {
  timeout: 1000,
};
