import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { operatorsReducer as operators } from '@otpusk/apisearch-toolbox/dist/operators';
import { geoReducer as geo } from '@otpusk/apisearch-toolbox/dist/geo';
import { hotelsReducer as hotels } from '@otpusk/apisearch-toolbox/dist/hotels';
import { offersReducer as offers } from '@otpusk/apisearch-toolbox/dist/offers';
import { servicesReducer as services } from '@otpusk/apisearch-toolbox/dist/services';

import auth from 'bus/auth/reducer';
import tfUsers from 'bus/tfUsers/reducer';
import ui from 'bus/ui/reducer';
import tf from 'bus/tf/reducer';
import detailDocs from 'bus/detailDocs/reducer';
import searchClientAndClaim from 'bus/searchClientAndClaim/reducer';
import searchClientLead from 'bus/searchClientLead/reducer';
import detailsFields from 'bus/detailsFields/reducer';
import { documentFieldsReducer as documentFields } from 'bus/documentFields';
import claim from 'bus/claim/reducer';
import clientClaim from 'bus/clientClaim/reducer';
import main from 'bus/main/reducer';
import files from 'bus/files/reducer';
import finance from 'bus/finance/reducer';
import common from 'bus/common/reducer';
import statistic from 'bus/statistic/reducer';
import templates from 'bus/templates/reducer';
import marketing from 'bus/marketing/reducer';
import calendar from 'bus/calendar/reducer';
import dump from 'bus/dump/reducer';
import booking from 'bus/booking/reducer';
import accounting from 'bus/accounting/reducer';
import routerUi from 'bus/routerUi/reducer';
import { notifyReducer as notify } from 'bus/notify';
import { newsReducer as news } from 'bus/news';
import { subscriptionReducer as subscription } from 'bus/subscription';
import otpusk from 'bus/otpusk/reducer';
import savedSearches from 'bus/savedSearches/reducer';
import binotel from 'services/Binotel/store/reducer';
import freshdesk from 'services/Freshdesk/store/reducer';
import ownModalsDispatcher from 'containers/modalsDispatcher/store/reducer';
import bookingAminPage from 'pages/Booking/children/store/reducer';
import { busTicketsReducer } from 'pages/BusTickets/store';
import { contractSignModalReducer as contractSignModal } from 'components/modals/ContractSignModal';

import { authActions } from 'bus/auth/actions';

import { history } from './history';

const appReducer = combineReducers({
  router: connectRouter(history),
  auth,
  tfUsers,
  ui,
  tf,
  detailDocs,
  searchClientAndClaim,
  searchClientLead,
  detailsFields,
  documentFields,
  claim,
  clientClaim,
  main,
  files,
  finance,
  common,
  statistic,
  templates,
  marketing,
  calendar,
  dump,
  booking,
  accounting,
  routerUi,
  notify,
  operators,
  geo,
  hotels,
  offers,
  services,
  news,
  subscription,
  otpusk,
  binotel,
  freshdesk,
  ownModalsDispatcher,
  savedSearches,
  bookingAminPage,
  busTickets: busTicketsReducer,
  contractSignModal
});

export const rootReducer = (state, action) => {
  const isLogOut = action.type === authActions.logOutSuccess.toString();

  return appReducer(isLogOut ? undefined : state, action);
};
