import { put, select, call } from 'redux-saga/effects';

import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { getClaimId } from 'bus/clientClaim/selectors';

import { createDigitalContractFromFiles } from 'api/methods/claim/digitalContract';

import { showToastError } from 'services/toaster';

export function* createDigitalContractFromFilesSaga({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const claimId = yield select(getClaimId);

  const { title, files } = payload;

  try {
    const bodyParams = new FormData();

    bodyParams.append('contract_number', title);

    for (const entity of files) {
      const { file, publicName } = entity;
      bodyParams.append('files[]', file);
      bodyParams.append('public_names[]', publicName);
    }

    const response = yield call(
      createDigitalContractFromFiles,
      token,
      { pathParams: { id: claimId }, bodyParams }
    );

    yield put(digitalContractActions.createContractFromFilesSuccess(response));
  } catch (error) {
    const { body } = error.msg || {};

    yield put(digitalContractActions.createContractFromFilesFail(error));

    showToastError(body?.message);
  }
}
