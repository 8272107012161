import { put, takeEvery, take, race, select, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { bookingActions } from 'bus/booking/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getToken, getUserId, } from 'bus/auth/selectors';
import { getBookingId } from 'bus/booking/selectors';
import { getMyManager } from 'bus/common/selectors';

import { newInstance } from 'localization';

import { updateBooking as updateBookingApi } from 'api/methods/booking';

import { showToastError, showToastSuccess } from 'services/toaster';

import { initAutoUpdateBooking, updateBooking, updateBookingWithReload } from './actions';

import { UPDATE_BOOKING_COMMON_UI } from './constants';

function* updateBookingSaga({ payload }) {
  const { values, formik, onSuccess } = payload;

  yield put(registerUI(createUi({ loading: true }), UPDATE_BOOKING_COMMON_UI));

  const { money, ...booking } = values;

  yield put(bookingActions.updateCash(money));

  const [isUpdateCashSuccess] = yield race([
    take(bookingActions.updateCashSuccess),
    take(bookingActions.updateCashFail),
  ]);

  if (isUpdateCashSuccess) {
    yield put(bookingActions.updateBooking(booking, formik));

    const [updatedSuccess] = yield race([
      take(bookingActions.updateBookingSuccess),
      take(bookingActions.updateBookingFail)
    ]);

    if (updatedSuccess) {
      showToastSuccess();
      onSuccess && onSuccess();
    } else showToastError();
  }

  yield put(updateUI({ loading: false }, UPDATE_BOOKING_COMMON_UI));
}

function* updateBookingWithReloadSaga({ payload: values }) {
  yield put(registerUI(createUi({ loading: true }), UPDATE_BOOKING_COMMON_UI));

  yield put(bookingActions.updateBooking(values, null));

  const [updatedSuccess] = yield race([
    take(bookingActions.updateBookingSuccess),
    take(bookingActions.updateBookingFail)
  ]);

  if (updatedSuccess) {
    global.window.location.reload();
  }

  yield put(updateUI({ loading: false }, UPDATE_BOOKING_COMMON_UI));
}

function* initAutoUpdateBookingSaga({ payload: { values, onSuccess } }) {
  const { processing, curator } = values;

  const enabledBooking = yield select(state => {
    const manager = getMyManager(state);

    return R.prop('auto_bookings_processing', manager);
  });

  if (processing || !enabledBooking) {
    return;
  }

  const userId = yield select(getUserId);

  if (!curator || curator === userId) {
    const token = yield select(getToken);
    const id = yield select(getBookingId);

    try {
      // TODO use processing constant after #45665
      const entity = {
        curator: userId,
        processing: 1
      };

      yield call(updateBookingApi, token, {
        pathParams: { id },
        bodyParams: entity,
      });

      onSuccess(entity);

      showToastSuccess(
        newInstance.t('BOOKING_ADMIN_PAGE:AUTO_UPDATE_BOOKING_SUCCESS')
      );
    } catch (error) {
      showToastError();
    }
  }
}

export function* bookingCommonRootSaga() {
  yield takeEvery(updateBooking, updateBookingSaga);
  yield takeEvery(updateBookingWithReload, updateBookingWithReloadSaga);
  yield takeEvery(initAutoUpdateBooking, initAutoUpdateBookingSaga);
}
