import { takeEvery } from 'redux-saga/effects';

import {
  autheticateWorker,
  fetchMyselfWorker,
  autoAuthenticateWorker,
  logOutWorker,
  registerWorker,
  createRegisterCodeWorker,
  forgetWorker,
  validateEmailWorker,
  showLimitUserModalWorker,
  logOutPureWorker,
  removeFacebookSaga
} from './workers';

import { authActions as actions } from '../actions';

export const authWatchers = Object.freeze({
  * autheticateWatcher() {
    yield takeEvery(actions.authenticate, autheticateWorker);
  },
  * autoAutheticateWatcher() {
    yield takeEvery(actions.autoAuthenticate, autoAuthenticateWorker);
  },
  * fetchMyselfWatcher() {
    yield takeEvery(actions.fetchMyself, fetchMyselfWorker);
  },
  * logOutWatcher() {
    yield takeEvery(actions.logOut, logOutWorker);
  },
  * showLimitUserModalWatcher() {
    yield takeEvery(actions.showLimitUserModal, showLimitUserModalWorker);
  },
  * pureLogOutWatcher() {
    yield takeEvery(actions.logOutPure, logOutPureWorker);
  },
  * registerWatcher() {
    yield takeEvery(actions.register, registerWorker);
  },
  * createRegisterCode() {
    yield takeEvery(actions.createRegisterCode, createRegisterCodeWorker);
  },
  * forgetWatcher() {
    yield takeEvery(actions.forget, forgetWorker);
  },
  * validateEmailWorker() {
    yield takeEvery(actions.validateEmail, validateEmailWorker);
  },
  * removeFacebookSaga() {
    yield takeEvery(actions.removeFacebook, removeFacebookSaga);
  },
});
